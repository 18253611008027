
/*
Template Name: Rocker Admin
Author: codervent
Email: codervent@gmail.com
File: icons.css
*/

@font-face {
	font-family: boxicons;
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/boxicons.eot');
	src: url('../fonts/boxicons.eot') format('embedded-opentype'), url('../fonts/boxicons.woff2') format('woff2'), url('../fonts/boxicons.woff') format('woff'), url('../fonts/boxicons.ttf') format('truetype'), url(../fonts/boxicons.svg?#boxicons) format('svg')
}

.bx {
	font-family: boxicons!important;
	font-weight: 400;
	font-style: normal;
	font-variant: normal;
	line-height: inherit;
	display: inline-block;
	text-transform: none;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.bx-ul {
	margin-left: 2em;
	padding-left: 0;
	list-style: none
}

.bx-ul>li {
	position: relative
}

.bx-ul .bx {
	font-size: inherit;
	line-height: inherit;
	position: absolute;
	left: -2em;
	width: 2em;
	text-align: center
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

@-webkit-keyframes burst {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1
	}
	90% {
		-webkit-transform: scale(1.5);
		transform: scale(1.5);
		opacity: 0
	}
}

@keyframes burst {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1
	}
	90% {
		-webkit-transform: scale(1.5);
		transform: scale(1.5);
		opacity: 0
	}
}

@-webkit-keyframes flashing {
	0% {
		opacity: 1
	}
	45% {
		opacity: 0
	}
	90% {
		opacity: 1
	}
}

@keyframes flashing {
	0% {
		opacity: 1
	}
	45% {
		opacity: 0
	}
	90% {
		opacity: 1
	}
}

@-webkit-keyframes fade-left {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1
	}
	75% {
		-webkit-transform: translateX(-20px);
		transform: translateX(-20px);
		opacity: 0
	}
}

@keyframes fade-left {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1
	}
	75% {
		-webkit-transform: translateX(-20px);
		transform: translateX(-20px);
		opacity: 0
	}
}

@-webkit-keyframes fade-right {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1
	}
	75% {
		-webkit-transform: translateX(20px);
		transform: translateX(20px);
		opacity: 0
	}
}

@keyframes fade-right {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1
	}
	75% {
		-webkit-transform: translateX(20px);
		transform: translateX(20px);
		opacity: 0
	}
}

@-webkit-keyframes fade-up {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1
	}
	75% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
		opacity: 0
	}
}

@keyframes fade-up {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1
	}
	75% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
		opacity: 0
	}
}

@-webkit-keyframes fade-down {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1
	}
	75% {
		-webkit-transform: translateY(20px);
		transform: translateY(20px);
		opacity: 0
	}
}

@keyframes fade-down {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1
	}
	75% {
		-webkit-transform: translateY(20px);
		transform: translateY(20px);
		opacity: 0
	}
}

@-webkit-keyframes tada {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1)
	}
	10%,
	20% {
		-webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
		transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg)
	}
	30%,
	50%,
	70%,
	90% {
		-webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
		transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg)
	}
	40%,
	60%,
	80% {
		-webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
		transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg)
	}
	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1)
	}
}

@keyframes tada {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1)
	}
	10%,
	20% {
		-webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
		transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg)
	}
	30%,
	50%,
	70%,
	90% {
		-webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
		transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg)
	}
	40%,
	60%,
	80% {
		-webkit-transform: rotate3d(0, 0, 1, -10deg);
		transform: rotate3d(0, 0, 1, -10deg)
	}
	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1)
	}
}

.bx-spin {
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite
}

.bx-spin-hover:hover {
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite
}

.bx-tada {
	-webkit-animation: tada 1.5s ease infinite;
	animation: tada 1.5s ease infinite
}

.bx-tada-hover:hover {
	-webkit-animation: tada 1.5s ease infinite;
	animation: tada 1.5s ease infinite
}

.bx-flashing {
	-webkit-animation: flashing 1.5s infinite linear;
	animation: flashing 1.5s infinite linear
}

.bx-flashing-hover:hover {
	-webkit-animation: flashing 1.5s infinite linear;
	animation: flashing 1.5s infinite linear
}

.bx-burst {
	-webkit-animation: burst 1.5s infinite linear;
	animation: burst 1.5s infinite linear
}

.bx-burst-hover:hover {
	-webkit-animation: burst 1.5s infinite linear;
	animation: burst 1.5s infinite linear
}

.bx-fade-up {
	-webkit-animation: fade-up 1.5s infinite linear;
	animation: fade-up 1.5s infinite linear
}

.bx-fade-up-hover:hover {
	-webkit-animation: fade-up 1.5s infinite linear;
	animation: fade-up 1.5s infinite linear
}

.bx-fade-down {
	-webkit-animation: fade-down 1.5s infinite linear;
	animation: fade-down 1.5s infinite linear
}

.bx-fade-down-hover:hover {
	-webkit-animation: fade-down 1.5s infinite linear;
	animation: fade-down 1.5s infinite linear
}

.bx-fade-left {
	-webkit-animation: fade-left 1.5s infinite linear;
	animation: fade-left 1.5s infinite linear
}

.bx-fade-left-hover:hover {
	-webkit-animation: fade-left 1.5s infinite linear;
	animation: fade-left 1.5s infinite linear
}

.bx-fade-right {
	-webkit-animation: fade-right 1.5s infinite linear;
	animation: fade-right 1.5s infinite linear
}

.bx-fade-right-hover:hover {
	-webkit-animation: fade-right 1.5s infinite linear;
	animation: fade-right 1.5s infinite linear
}

.bx-xs {
	font-size: 1rem!important
}

.bx-sm {
	font-size: 1.55rem!important
}

.bx-md {
	font-size: 2.25rem!important
}

.bx-fw {
	font-size: 1.2857142857em;
	line-height: .8em;
	width: 1.2857142857em;
	height: .8em;
	margin-top: -.2em!important;
	vertical-align: middle
}

.bx-lg {
	font-size: 3rem!important
}

.bx-pull-left {
	float: left;
	margin-right: .3em!important
}

.bx-pull-right {
	float: right;
	margin-left: .3em!important
}

.bx-rotate-90 {
	transform: rotate(90deg)
}

.bx-rotate-180 {
	transform: rotate(180deg)
}

.bx-rotate-270 {
	transform: rotate(270deg)
}

.bx-flip-horizontal {
	transform: scaleX(-1)
}

.bx-flip-vertical {
	transform: scaleY(-1)
}

.bx-border {
	padding: .25em;
	border: .07em solid rgba(0, 0, 0, .1);
	border-radius: .25em
}

.bx-border-circle {
	padding: .25em;
	border: .07em solid rgba(0, 0, 0, .1);
	border-radius: 50%
}

.bx-abacus:before {
	content: "\e900"
}

.bx-accessibility:before {
	content: "\e901"
}

.bx-add-to-queue:before {
	content: "\e902"
}

.bx-adjust:before {
	content: "\e903"
}

.bx-alarm:before {
	content: "\e904"
}

.bx-alarm-add:before {
	content: "\e905"
}

.bx-alarm-exclamation:before {
	content: "\e906"
}

.bx-alarm-off:before {
	content: "\e907"
}

.bx-alarm-snooze:before {
	content: "\e908"
}

.bx-album:before {
	content: "\e909"
}

.bx-align-justify:before {
	content: "\e90a"
}

.bx-align-left:before {
	content: "\e90b"
}

.bx-align-middle:before {
	content: "\e90c"
}

.bx-align-right:before {
	content: "\e90d"
}

.bx-analyse:before {
	content: "\e90e"
}

.bx-anchor:before {
	content: "\e90f"
}

.bx-angry:before {
	content: "\e910"
}

.bx-aperture:before {
	content: "\e911"
}

.bx-arch:before {
	content: "\e912"
}

.bx-archive:before {
	content: "\e913"
}

.bx-archive-in:before {
	content: "\e914"
}

.bx-archive-out:before {
	content: "\e915"
}

.bx-area:before {
	content: "\e916"
}

.bx-arrow-back:before {
	content: "\e917"
}

.bx-arrow-from-bottom:before {
	content: "\e918"
}

.bx-arrow-from-left:before {
	content: "\e919"
}

.bx-arrow-from-right:before {
	content: "\e91a"
}

.bx-arrow-from-top:before {
	content: "\e91b"
}

.bx-arrow-to-bottom:before {
	content: "\e91c"
}

.bx-arrow-to-left:before {
	content: "\e91d"
}

.bx-arrow-to-right:before {
	content: "\e91e"
}

.bx-arrow-to-top:before {
	content: "\e91f"
}

.bx-at:before {
	content: "\e920"
}

.bx-atom:before {
	content: "\e921"
}

.bx-award:before {
	content: "\e922"
}

.bx-badge:before {
	content: "\e923"
}

.bx-badge-check:before {
	content: "\e924"
}

.bx-ball:before {
	content: "\e925"
}

.bx-band-aid:before {
	content: "\e926"
}

.bx-bar-chart:before {
	content: "\e927"
}

.bx-bar-chart-alt:before {
	content: "\e928"
}

.bx-bar-chart-alt-2:before {
	content: "\e929"
}

.bx-bar-chart-square:before {
	content: "\e92a"
}

.bx-barcode:before {
	content: "\e92b"
}

.bx-barcode-reader:before {
	content: "\e92c"
}

.bx-baseball:before {
	content: "\e92d"
}

.bx-basket:before {
	content: "\e92e"
}

.bx-basketball:before {
	content: "\e92f"
}

.bx-bath:before {
	content: "\e930"
}

.bx-battery:before {
	content: "\e931"
}

.bx-bed:before {
	content: "\e932"
}

.bx-been-here:before {
	content: "\e933"
}

.bx-beer:before {
	content: "\e934"
}

.bx-bell:before {
	content: "\e935"
}

.bx-bell-minus:before {
	content: "\e936"
}

.bx-bell-off:before {
	content: "\e937"
}

.bx-bell-plus:before {
	content: "\e938"
}

.bx-bible:before {
	content: "\e939"
}

.bx-bitcoin:before {
	content: "\e93a"
}

.bx-blanket:before {
	content: "\e93b"
}

.bx-block:before {
	content: "\e93c"
}

.bx-bluetooth:before {
	content: "\e93d"
}

.bx-body:before {
	content: "\e93e"
}

.bx-bold:before {
	content: "\e93f"
}

.bx-bolt-circle:before {
	content: "\e940"
}

.bx-bomb:before {
	content: "\e941"
}

.bx-bone:before {
	content: "\e942"
}

.bx-bong:before {
	content: "\e943"
}

.bx-book:before {
	content: "\e944"
}

.bx-book-add:before {
	content: "\e945"
}

.bx-book-alt:before {
	content: "\e946"
}

.bx-book-bookmark:before {
	content: "\e947"
}

.bx-book-content:before {
	content: "\e948"
}

.bx-book-heart:before {
	content: "\e949"
}

.bx-bookmark:before {
	content: "\e94a"
}

.bx-bookmark-alt:before {
	content: "\e94b"
}

.bx-bookmark-alt-minus:before {
	content: "\e94c"
}

.bx-bookmark-alt-plus:before {
	content: "\e94d"
}

.bx-bookmark-heart:before {
	content: "\e94e"
}

.bx-bookmark-minus:before {
	content: "\e94f"
}

.bx-bookmark-plus:before {
	content: "\e950"
}

.bx-bookmarks:before {
	content: "\e951"
}

.bx-book-open:before {
	content: "\e952"
}

.bx-book-reader:before {
	content: "\e953"
}

.bx-border-all:before {
	content: "\e954"
}

.bx-border-bottom:before {
	content: "\e955"
}

.bx-border-inner:before {
	content: "\e956"
}

.bx-border-left:before {
	content: "\e957"
}

.bx-border-none:before {
	content: "\e958"
}

.bx-border-outer:before {
	content: "\e959"
}

.bx-border-radius:before {
	content: "\e95a"
}

.bx-border-right:before {
	content: "\e95b"
}

.bx-border-top:before {
	content: "\e95c"
}

.bx-bot:before {
	content: "\e95d"
}

.bx-bowling-ball:before {
	content: "\e95e"
}

.bx-box:before {
	content: "\e95f"
}

.bx-bracket:before {
	content: "\e960"
}

.bx-braille:before {
	content: "\e961"
}

.bx-brain:before {
	content: "\e962"
}

.bx-briefcase:before {
	content: "\e963"
}

.bx-briefcase-alt:before {
	content: "\e964"
}

.bx-briefcase-alt-2:before {
	content: "\e965"
}

.bx-brightness:before {
	content: "\e966"
}

.bx-brightness-half:before {
	content: "\e967"
}

.bx-broadcast:before {
	content: "\e968"
}

.bx-brush:before {
	content: "\e969"
}

.bx-brush-alt:before {
	content: "\e96a"
}

.bx-bug:before {
	content: "\e96b"
}

.bx-bug-alt:before {
	content: "\e96c"
}

.bx-building:before {
	content: "\e96d"
}

.bx-building-house:before {
	content: "\e96e"
}

.bx-buildings:before {
	content: "\e96f"
}

.bx-bulb:before {
	content: "\e970"
}

.bx-bullseye:before {
	content: "\e971"
}

.bx-buoy:before {
	content: "\e972"
}

.bx-bus:before {
	content: "\e973"
}

.bx-bus-school:before {
	content: "\e974"
}

.bx-cabinet:before {
	content: "\e975"
}

.bx-cake:before {
	content: "\e976"
}

.bx-calculator:before {
	content: "\e977"
}

.bx-calendar:before {
	content: "\e978"
}

.bx-calendar-alt:before {
	content: "\e979"
}

.bx-calendar-check:before {
	content: "\e97a"
}

.bx-calendar-edit:before {
	content: "\e97b"
}

.bx-calendar-event:before {
	content: "\e97c"
}

.bx-calendar-exclamation:before {
	content: "\e97d"
}

.bx-calendar-heart:before {
	content: "\e97e"
}

.bx-calendar-minus:before {
	content: "\e97f"
}

.bx-calendar-plus:before {
	content: "\e980"
}

.bx-calendar-star:before {
	content: "\e981"
}

.bx-calendar-week:before {
	content: "\e982"
}

.bx-calendar-x:before {
	content: "\e983"
}

.bx-camera:before {
	content: "\e984"
}

.bx-camera-home:before {
	content: "\e985"
}

.bx-camera-movie:before {
	content: "\e986"
}

.bx-camera-off:before {
	content: "\e987"
}

.bx-capsule:before {
	content: "\e988"
}

.bx-captions:before {
	content: "\e989"
}

.bx-car:before {
	content: "\e98a"
}

.bx-card:before {
	content: "\e98b"
}

.bx-caret-down:before {
	content: "\e98c"
}

.bx-caret-down-circle:before {
	content: "\e98d"
}

.bx-caret-down-square:before {
	content: "\e98e"
}

.bx-caret-left:before {
	content: "\e98f"
}

.bx-caret-left-circle:before {
	content: "\e990"
}

.bx-caret-left-square:before {
	content: "\e991"
}

.bx-caret-right:before {
	content: "\e992"
}

.bx-caret-right-circle:before {
	content: "\e993"
}

.bx-caret-right-square:before {
	content: "\e994"
}

.bx-caret-up:before {
	content: "\e995"
}

.bx-caret-up-circle:before {
	content: "\e996"
}

.bx-caret-up-square:before {
	content: "\e997"
}

.bx-carousel:before {
	content: "\e998"
}

.bx-cart:before {
	content: "\e999"
}

.bx-cart-alt:before {
	content: "\e99a"
}

.bx-cast:before {
	content: "\e99b"
}

.bx-category:before {
	content: "\e99c"
}

.bx-category-alt:before {
	content: "\e99d"
}

.bx-cctv:before {
	content: "\e99e"
}

.bx-certification:before {
	content: "\e99f"
}

.bx-chair:before {
	content: "\e9a0"
}

.bx-chalkboard:before {
	content: "\e9a1"
}

.bx-chart:before {
	content: "\e9a2"
}

.bx-chat:before {
	content: "\e9a3"
}

.bx-check:before {
	content: "\e9a4"
}

.bx-checkbox:before {
	content: "\e9a5"
}

.bx-checkbox-checked:before {
	content: "\e9a6"
}

.bx-checkbox-square:before {
	content: "\e9a7"
}

.bx-check-circle:before {
	content: "\e9a8"
}

.bx-check-double:before {
	content: "\e9a9"
}

.bx-check-shield:before {
	content: "\e9aa"
}

.bx-check-square:before {
	content: "\e9ab"
}

.bx-chevron-down:before {
	content: "\e9ac"
}

.bx-chevron-down-circle:before {
	content: "\e9ad"
}

.bx-chevron-down-square:before {
	content: "\e9ae"
}

.bx-chevron-left:before {
	content: "\e9af"
}

.bx-chevron-left-circle:before {
	content: "\e9b0"
}

.bx-chevron-left-square:before {
	content: "\e9b1"
}

.bx-chevron-right:before {
	content: "\e9b2"
}

.bx-chevron-right-circle:before {
	content: "\e9b3"
}

.bx-chevron-right-square:before {
	content: "\e9b4"
}

.bx-chevrons-down:before {
	content: "\e9b5"
}

.bx-chevrons-left:before {
	content: "\e9b6"
}

.bx-chevrons-right:before {
	content: "\e9b7"
}

.bx-chevrons-up:before {
	content: "\e9b8"
}

.bx-chevron-up:before {
	content: "\e9b9"
}

.bx-chevron-up-circle:before {
	content: "\e9ba"
}

.bx-chevron-up-square:before {
	content: "\e9bb"
}

.bx-chip:before {
	content: "\e9bc"
}

.bx-church:before {
	content: "\e9bd"
}

.bx-circle:before {
	content: "\e9be"
}

.bx-clinic:before {
	content: "\e9bf"
}

.bx-clipboard:before {
	content: "\e9c0"
}

.bx-closet:before {
	content: "\e9c1"
}

.bx-cloud:before {
	content: "\e9c2"
}

.bx-cloud-download:before {
	content: "\e9c3"
}

.bx-cloud-drizzle:before {
	content: "\e9c4"
}

.bx-cloud-lightning:before {
	content: "\e9c5"
}

.bx-cloud-light-rain:before {
	content: "\e9c6"
}

.bx-cloud-rain:before {
	content: "\e9c7"
}

.bx-cloud-snow:before {
	content: "\e9c8"
}

.bx-cloud-upload:before {
	content: "\e9c9"
}

.bx-code:before {
	content: "\e9ca"
}

.bx-code-alt:before {
	content: "\e9cb"
}

.bx-code-block:before {
	content: "\e9cc"
}

.bx-code-curly:before {
	content: "\e9cd"
}

.bx-coffee:before {
	content: "\e9ce"
}

.bx-coffee-togo:before {
	content: "\e9cf"
}

.bx-cog:before {
	content: "\e9d0"
}

.bx-coin:before {
	content: "\e9d1"
}

.bx-coin-stack:before {
	content: "\e9d2"
}

.bx-collapse:before {
	content: "\e9d3"
}

.bx-collection:before {
	content: "\e9d4"
}

.bx-color-fill:before {
	content: "\e9d5"
}

.bx-columns:before {
	content: "\e9d6"
}

.bx-command:before {
	content: "\e9d7"
}

.bx-comment:before {
	content: "\e9d8"
}

.bx-comment-add:before {
	content: "\e9d9"
}

.bx-comment-check:before {
	content: "\e9da"
}

.bx-comment-detail:before {
	content: "\e9db"
}

.bx-comment-dots:before {
	content: "\e9dc"
}

.bx-comment-edit:before {
	content: "\e9dd"
}

.bx-comment-error:before {
	content: "\e9de"
}

.bx-comment-minus:before {
	content: "\e9df"
}

.bx-comment-x:before {
	content: "\e9e0"
}

.bx-compass:before {
	content: "\e9e1"
}

.bx-confused:before {
	content: "\e9e2"
}

.bx-conversation:before {
	content: "\e9e3"
}

.bx-cookie:before {
	content: "\e9e4"
}

.bx-cool:before {
	content: "\e9e5"
}

.bx-copy:before {
	content: "\e9e6"
}

.bx-copy-alt:before {
	content: "\e9e7"
}

.bx-copyright:before {
	content: "\e9e8"
}

.bx-credit-card:before {
	content: "\e9e9"
}

.bx-credit-card-alt:before {
	content: "\e9ea"
}

.bx-credit-card-front:before {
	content: "\e9eb"
}

.bx-crop:before {
	content: "\e9ec"
}

.bx-crosshair:before {
	content: "\e9ed"
}

.bx-crown:before {
	content: "\e9ee"
}

.bx-cube:before {
	content: "\e9ef"
}

.bx-cube-alt:before {
	content: "\e9f0"
}

.bx-cuboid:before {
	content: "\e9f1"
}

.bx-current-location:before {
	content: "\e9f2"
}

.bx-customize:before {
	content: "\e9f3"
}

.bx-cut:before {
	content: "\e9f4"
}

.bx-cycling:before {
	content: "\e9f5"
}

.bx-cylinder:before {
	content: "\e9f6"
}

.bx-data:before {
	content: "\e9f7"
}

.bx-desktop:before {
	content: "\e9f8"
}

.bx-detail:before {
	content: "\e9f9"
}

.bx-devices:before {
	content: "\e9fa"
}

.bx-dialpad:before {
	content: "\e9fb"
}

.bx-dialpad-alt:before {
	content: "\e9fc"
}

.bx-diamond:before {
	content: "\e9fd"
}

.bx-dice-1:before {
	content: "\e9fe"
}

.bx-dice-2:before {
	content: "\e9ff"
}

.bx-dice-3:before {
	content: "\ea00"
}

.bx-dice-4:before {
	content: "\ea01"
}

.bx-dice-5:before {
	content: "\ea02"
}

.bx-dice-6:before {
	content: "\ea03"
}

.bx-directions:before {
	content: "\ea04"
}

.bx-disc:before {
	content: "\ea05"
}

.bx-dish:before {
	content: "\ea06"
}

.bx-dislike:before {
	content: "\ea07"
}

.bx-dizzy:before {
	content: "\ea08"
}

.bx-dna:before {
	content: "\ea09"
}

.bx-dock-bottom:before {
	content: "\ea0a"
}

.bx-dock-left:before {
	content: "\ea0b"
}

.bx-dock-right:before {
	content: "\ea0c"
}

.bx-dock-top:before {
	content: "\ea0d"
}

.bx-dollar:before {
	content: "\ea0e"
}

.bx-dollar-circle:before {
	content: "\ea0f"
}

.bx-donate-blood:before {
	content: "\ea10"
}

.bx-donate-heart:before {
	content: "\ea11"
}

.bx-door-open:before {
	content: "\ea12"
}

.bx-dots-horizontal:before {
	content: "\ea13"
}

.bx-dots-horizontal-rounded:before {
	content: "\ea14"
}

.bx-dots-vertical:before {
	content: "\ea15"
}

.bx-dots-vertical-rounded:before {
	content: "\ea16"
}

.bx-doughnut-chart:before {
	content: "\ea17"
}

.bx-down-arrow:before {
	content: "\ea18"
}

.bx-down-arrow-alt:before {
	content: "\ea19"
}

.bx-down-arrow-circle:before {
	content: "\ea1a"
}

.bx-download:before {
	content: "\ea1b"
}

.bx-downvote:before {
	content: "\ea1c"
}

.bx-drink:before {
	content: "\ea1d"
}

.bx-droplet:before {
	content: "\ea1e"
}

.bx-dumbbell:before {
	content: "\ea1f"
}

.bx-duplicate:before {
	content: "\ea20"
}

.bx-edit:before {
	content: "\ea21"
}

.bx-edit-alt:before {
	content: "\ea22"
}

.bx-envelope:before {
	content: "\ea23"
}

.bx-envelope-open:before {
	content: "\ea24"
}

.bx-equalizer:before {
	content: "\ea25"
}

.bx-eraser:before {
	content: "\ea26"
}

.bx-error:before {
	content: "\ea27"
}

.bx-error-alt:before {
	content: "\ea28"
}

.bx-error-circle:before {
	content: "\ea29"
}

.bx-euro:before {
	content: "\ea2a"
}

.bx-exclude:before {
	content: "\ea2b"
}

.bx-exit:before {
	content: "\ea2c"
}

.bx-exit-fullscreen:before {
	content: "\ea2d"
}

.bx-expand:before {
	content: "\ea2e"
}

.bx-expand-alt:before {
	content: "\ea2f"
}

.bx-export:before {
	content: "\ea30"
}

.bx-extension:before {
	content: "\ea31"
}

.bx-face:before {
	content: "\ea32"
}

.bx-fast-forward:before {
	content: "\ea33"
}

.bx-fast-forward-circle:before {
	content: "\ea34"
}

.bx-female:before {
	content: "\ea35"
}

.bx-female-sign:before {
	content: "\ea36"
}

.bx-file:before {
	content: "\ea37"
}

.bx-file-blank:before {
	content: "\ea38"
}

.bx-file-find:before {
	content: "\ea39"
}

.bx-film:before {
	content: "\ea3a"
}

.bx-filter:before {
	content: "\ea3b"
}

.bx-filter-alt:before {
	content: "\ea3c"
}

.bx-fingerprint:before {
	content: "\ea3d"
}

.bx-first-aid:before {
	content: "\ea3e"
}

.bx-first-page:before {
	content: "\ea3f"
}

.bx-flag:before {
	content: "\ea40"
}

.bx-folder:before {
	content: "\ea41"
}

.bx-folder-minus:before {
	content: "\ea42"
}

.bx-folder-open:before {
	content: "\ea43"
}

.bx-folder-plus:before {
	content: "\ea44"
}

.bx-font:before {
	content: "\ea45"
}

.bx-font-color:before {
	content: "\ea46"
}

.bx-font-family:before {
	content: "\ea47"
}

.bx-font-size:before {
	content: "\ea48"
}

.bx-food-menu:before {
	content: "\ea49"
}

.bx-food-tag:before {
	content: "\ea4a"
}

.bx-football:before {
	content: "\ea4b"
}

.bx-fridge:before {
	content: "\ea4c"
}

.bx-fullscreen:before {
	content: "\ea4d"
}

.bx-game:before {
	content: "\ea4e"
}

.bx-gas-pump:before {
	content: "\ea4f"
}

.bx-ghost:before {
	content: "\ea50"
}

.bx-gift:before {
	content: "\ea51"
}

.bx-git-branch:before {
	content: "\ea52"
}

.bx-git-commit:before {
	content: "\ea53"
}

.bx-git-compare:before {
	content: "\ea54"
}

.bx-git-merge:before {
	content: "\ea55"
}

.bx-git-pull-request:before {
	content: "\ea56"
}

.bx-git-repo-forked:before {
	content: "\ea57"
}

.bx-glasses:before {
	content: "\ea58"
}

.bx-glasses-alt:before {
	content: "\ea59"
}

.bx-globe:before {
	content: "\ea5a"
}

.bx-globe-alt:before {
	content: "\ea5b"
}

.bx-grid:before {
	content: "\ea5c"
}

.bx-grid-alt:before {
	content: "\ea5d"
}

.bx-grid-horizontal:before {
	content: "\ea5e"
}

.bx-grid-small:before {
	content: "\ea5f"
}

.bx-grid-vertical:before {
	content: "\ea60"
}

.bx-group:before {
	content: "\ea61"
}

.bx-handicap:before {
	content: "\ea62"
}

.bx-happy:before {
	content: "\ea63"
}

.bx-happy-alt:before {
	content: "\ea64"
}

.bx-happy-beaming:before {
	content: "\ea65"
}

.bx-happy-heart-eyes:before {
	content: "\ea66"
}

.bx-hash:before {
	content: "\ea67"
}

.bx-hdd:before {
	content: "\ea68"
}

.bx-heading:before {
	content: "\ea69"
}

.bx-headphone:before {
	content: "\ea6a"
}

.bx-health:before {
	content: "\ea6b"
}

.bx-heart:before {
	content: "\ea6c"
}

.bx-heart-circle:before {
	content: "\ea6d"
}

.bx-heart-square:before {
	content: "\ea6e"
}

.bx-help-circle:before {
	content: "\ea6f"
}

.bx-hide:before {
	content: "\ea70"
}

.bx-highlight:before {
	content: "\ea71"
}

.bx-history:before {
	content: "\ea72"
}

.bx-hive:before {
	content: "\ea73"
}

.bx-home:before {
	content: "\ea74"
}

.bx-home-alt:before {
	content: "\ea75"
}

.bx-home-circle:before {
	content: "\ea76"
}

.bx-home-heart:before {
	content: "\ea77"
}

.bx-home-smile:before {
	content: "\ea78"
}

.bx-horizontal-center:before {
	content: "\ea79"
}

.bx-hotel:before {
	content: "\ea7a"
}

.bx-hourglass:before {
	content: "\ea7b"
}

.bx-id-card:before {
	content: "\ea7c"
}

.bx-image:before {
	content: "\ea7d"
}

.bx-image-add:before {
	content: "\ea7e"
}

.bx-image-alt:before {
	content: "\ea7f"
}

.bx-images:before {
	content: "\ea80"
}

.bx-import:before {
	content: "\ea81"
}

.bx-infinite:before {
	content: "\ea82"
}

.bx-info-circle:before {
	content: "\ea83"
}

.bx-info-square:before {
	content: "\ea84"
}

.bx-intersect:before {
	content: "\ea85"
}

.bx-italic:before {
	content: "\ea86"
}

.bx-joystick:before {
	content: "\ea87"
}

.bx-joystick-alt:before {
	content: "\ea88"
}

.bx-joystick-button:before {
	content: "\ea89"
}

.bx-key:before {
	content: "\ea8a"
}

.bx-label:before {
	content: "\ea8b"
}

.bx-landscape:before {
	content: "\ea8c"
}

.bx-laptop:before {
	content: "\ea8d"
}

.bx-last-page:before {
	content: "\ea8e"
}

.bx-laugh:before {
	content: "\ea8f"
}

.bx-layer:before {
	content: "\ea90"
}

.bx-layer-minus:before {
	content: "\ea91"
}

.bx-layer-plus:before {
	content: "\ea92"
}

.bx-layout:before {
	content: "\ea93"
}

.bx-left-arrow:before {
	content: "\ea94"
}

.bx-left-arrow-alt:before {
	content: "\ea95"
}

.bx-left-arrow-circle:before {
	content: "\ea96"
}

.bx-left-down-arrow-circle:before {
	content: "\ea97"
}

.bx-left-indent:before {
	content: "\ea98"
}

.bx-left-top-arrow-circle:before {
	content: "\ea99"
}

.bx-library:before {
	content: "\ea9a"
}

.bx-like:before {
	content: "\ea9b"
}

.bx-line-chart:before {
	content: "\ea9c"
}

.bx-line-chart-down:before {
	content: "\ea9d"
}

.bx-link:before {
	content: "\ea9e"
}

.bx-link-alt:before {
	content: "\ea9f"
}

.bx-link-external:before {
	content: "\eaa0"
}

.bx-lira:before {
	content: "\eaa1"
}

.bx-list-check:before {
	content: "\eaa2"
}

.bx-list-minus:before {
	content: "\eaa3"
}

.bx-list-ol:before {
	content: "\eaa4"
}

.bx-list-plus:before {
	content: "\eaa5"
}

.bx-list-ul:before {
	content: "\eaa6"
}

.bx-loader:before {
	content: "\eaa7"
}

.bx-loader-alt:before {
	content: "\eaa8"
}

.bx-loader-circle:before {
	content: "\eaa9"
}

.bx-location-plus:before {
	content: "\eaaa"
}

.bx-lock:before {
	content: "\eaab"
}

.bx-lock-alt:before {
	content: "\eaac"
}

.bx-lock-open:before {
	content: "\eaad"
}

.bx-lock-open-alt:before {
	content: "\eaae"
}

.bx-log-in:before {
	content: "\eaaf"
}

.bx-log-in-circle:before {
	content: "\eab0"
}

.bx-log-out:before {
	content: "\eab1"
}

.bx-log-out-circle:before {
	content: "\eab2"
}

.bx-low-vision:before {
	content: "\eab3"
}

.bx-magnet:before {
	content: "\eab4"
}

.bx-mail-send:before {
	content: "\eab5"
}

.bx-male:before {
	content: "\eab6"
}

.bx-male-sign:before {
	content: "\eab7"
}

.bx-map:before {
	content: "\eab8"
}

.bx-map-alt:before {
	content: "\eab9"
}

.bx-map-pin:before {
	content: "\eaba"
}

.bx-mask:before {
	content: "\eabb"
}

.bx-medal:before {
	content: "\eabc"
}

.bx-meh:before {
	content: "\eabd"
}

.bx-meh-alt:before {
	content: "\eabe"
}

.bx-meh-blank:before {
	content: "\eabf"
}

.bx-memory-card:before {
	content: "\eac0"
}

.bx-menu:before {
	content: "\eac1"
}

.bx-menu-alt-left:before {
	content: "\eac2"
}

.bx-menu-alt-right:before {
	content: "\eac3"
}

.bx-merge:before {
	content: "\eac4"
}

.bx-message:before {
	content: "\eac5"
}

.bx-message-add:before {
	content: "\eac6"
}

.bx-message-alt:before {
	content: "\eac7"
}

.bx-message-alt-add:before {
	content: "\eac8"
}

.bx-message-alt-check:before {
	content: "\eac9"
}

.bx-message-alt-detail:before {
	content: "\eaca"
}

.bx-message-alt-dots:before {
	content: "\eacb"
}

.bx-message-alt-edit:before {
	content: "\eacc"
}

.bx-message-alt-error:before {
	content: "\eacd"
}

.bx-message-alt-minus:before {
	content: "\eace"
}

.bx-message-alt-x:before {
	content: "\eacf"
}

.bx-message-check:before {
	content: "\ead0"
}

.bx-message-detail:before {
	content: "\ead1"
}

.bx-message-dots:before {
	content: "\ead2"
}

.bx-message-edit:before {
	content: "\ead3"
}

.bx-message-error:before {
	content: "\ead4"
}

.bx-message-minus:before {
	content: "\ead5"
}

.bx-message-rounded:before {
	content: "\ead6"
}

.bx-message-rounded-add:before {
	content: "\ead7"
}

.bx-message-rounded-check:before {
	content: "\ead8"
}

.bx-message-rounded-detail:before {
	content: "\ead9"
}

.bx-message-rounded-dots:before {
	content: "\eada"
}

.bx-message-rounded-edit:before {
	content: "\eadb"
}

.bx-message-rounded-error:before {
	content: "\eadc"
}

.bx-message-rounded-minus:before {
	content: "\eadd"
}

.bx-message-rounded-x:before {
	content: "\eade"
}

.bx-message-square:before {
	content: "\eadf"
}

.bx-message-square-add:before {
	content: "\eae0"
}

.bx-message-square-check:before {
	content: "\eae1"
}

.bx-message-square-detail:before {
	content: "\eae2"
}

.bx-message-square-dots:before {
	content: "\eae3"
}

.bx-message-square-edit:before {
	content: "\eae4"
}

.bx-message-square-error:before {
	content: "\eae5"
}

.bx-message-square-minus:before {
	content: "\eae6"
}

.bx-message-square-x:before {
	content: "\eae7"
}

.bx-message-x:before {
	content: "\eae8"
}

.bx-meteor:before {
	content: "\eae9"
}

.bx-microchip:before {
	content: "\eaea"
}

.bx-microphone:before {
	content: "\eaeb"
}

.bx-microphone-off:before {
	content: "\eaec"
}

.bx-minus:before {
	content: "\eaed"
}

.bx-minus-back:before {
	content: "\eaee"
}

.bx-minus-circle:before {
	content: "\eaef"
}

.bx-minus-front:before {
	content: "\eaf0"
}

.bx-mobile:before {
	content: "\eaf1"
}

.bx-mobile-alt:before {
	content: "\eaf2"
}

.bx-mobile-landscape:before {
	content: "\eaf3"
}

.bx-mobile-vibration:before {
	content: "\eaf4"
}

.bx-money:before {
	content: "\eaf5"
}

.bx-moon:before {
	content: "\eaf6"
}

.bx-mouse:before {
	content: "\eaf7"
}

.bx-mouse-alt:before {
	content: "\eaf8"
}

.bx-move:before {
	content: "\eaf9"
}

.bx-move-horizontal:before {
	content: "\eafa"
}

.bx-move-vertical:before {
	content: "\eafb"
}

.bx-movie:before {
	content: "\eafc"
}

.bx-movie-play:before {
	content: "\eafd"
}

.bx-music:before {
	content: "\eafe"
}

.bx-navigation:before {
	content: "\eaff"
}

.bx-network-chart:before {
	content: "\eb00"
}

.bx-news:before {
	content: "\eb01"
}

.bx-no-entry:before {
	content: "\eb02"
}

.bx-note:before {
	content: "\eb03"
}

.bx-notepad:before {
	content: "\eb04"
}

.bx-notification:before {
	content: "\eb05"
}

.bx-notification-off:before {
	content: "\eb06"
}

.bx-outline:before {
	content: "\eb07"
}

.bx-package:before {
	content: "\eb08"
}

.bx-paint:before {
	content: "\eb09"
}

.bx-paint-roll:before {
	content: "\eb0a"
}

.bx-palette:before {
	content: "\eb0b"
}

.bx-paperclip:before {
	content: "\eb0c"
}

.bx-paper-plane:before {
	content: "\eb0d"
}

.bx-paragraph:before {
	content: "\eb0e"
}

.bx-paste:before {
	content: "\eb0f"
}

.bx-pause:before {
	content: "\eb10"
}

.bx-pause-circle:before {
	content: "\eb11"
}

.bx-pen:before {
	content: "\eb12"
}

.bx-pencil:before {
	content: "\eb13"
}

.bx-phone:before {
	content: "\eb14"
}

.bx-phone-call:before {
	content: "\eb15"
}

.bx-phone-incoming:before {
	content: "\eb16"
}

.bx-phone-outgoing:before {
	content: "\eb17"
}

.bx-photo-album:before {
	content: "\eb18"
}

.bx-pie-chart:before {
	content: "\eb19"
}

.bx-pie-chart-alt:before {
	content: "\eb1a"
}

.bx-pie-chart-alt-2:before {
	content: "\eb1b"
}

.bx-pin:before {
	content: "\eb1c"
}

.bx-planet:before {
	content: "\eb1d"
}

.bx-play:before {
	content: "\eb1e"
}

.bx-play-circle:before {
	content: "\eb1f"
}

.bx-plug:before {
	content: "\eb20"
}

.bx-plus:before {
	content: "\eb21"
}

.bx-plus-circle:before {
	content: "\eb22"
}

.bx-plus-medical:before {
	content: "\eb23"
}

.bx-pointer:before {
	content: "\eb24"
}

.bx-poll:before {
	content: "\eb25"
}

.bx-polygon:before {
	content: "\eb26"
}

.bx-pound:before {
	content: "\eb27"
}

.bx-power-off:before {
	content: "\eb28"
}

.bx-printer:before {
	content: "\eb29"
}

.bx-pulse:before {
	content: "\eb2a"
}

.bx-purchase-tag:before {
	content: "\eb2b"
}

.bx-purchase-tag-alt:before {
	content: "\eb2c"
}

.bx-pyramid:before {
	content: "\eb2d"
}

.bx-question-mark:before {
	content: "\eb2e"
}

.bx-radar:before {
	content: "\eb2f"
}

.bx-radio:before {
	content: "\eb30"
}

.bx-radio-circle:before {
	content: "\eb31"
}

.bx-radio-circle-marked:before {
	content: "\eb32"
}

.bx-receipt:before {
	content: "\eb33"
}

.bx-rectangle:before {
	content: "\eb34"
}

.bx-recycle:before {
	content: "\eb35"
}

.bx-redo:before {
	content: "\eb36"
}

.bx-refresh:before {
	content: "\eb37"
}

.bx-rename:before {
	content: "\eb38"
}

.bx-repeat:before {
	content: "\eb39"
}

.bx-reply:before {
	content: "\eb3a"
}

.bx-reply-all:before {
	content: "\eb3b"
}

.bx-repost:before {
	content: "\eb3c"
}

.bx-reset:before {
	content: "\eb3d"
}

.bx-restaurant:before {
	content: "\eb3e"
}

.bx-revision:before {
	content: "\eb3f"
}

.bx-rewind:before {
	content: "\eb40"
}

.bx-rewind-circle:before {
	content: "\eb41"
}

.bx-right-arrow:before {
	content: "\eb42"
}

.bx-right-arrow-alt:before {
	content: "\eb43"
}

.bx-right-arrow-circle:before {
	content: "\eb44"
}

.bx-right-down-arrow-circle:before {
	content: "\eb45"
}

.bx-right-indent:before {
	content: "\eb46"
}

.bx-right-top-arrow-circle:before {
	content: "\eb47"
}

.bx-rocket:before {
	content: "\eb48"
}

.bx-rotate-left:before {
	content: "\eb49"
}

.bx-rotate-right:before {
	content: "\eb4a"
}

.bx-rss:before {
	content: "\eb4b"
}

.bx-ruble:before {
	content: "\eb4c"
}

.bx-ruler:before {
	content: "\eb4d"
}

.bx-run:before {
	content: "\eb4e"
}

.bx-rupee:before {
	content: "\eb4f"
}

.bx-sad:before {
	content: "\eb50"
}

.bx-save:before {
	content: "\eb51"
}

.bx-scan:before {
	content: "\eb52"
}

.bx-screenshot:before {
	content: "\eb53"
}

.bx-search:before {
	content: "\eb54"
}

.bx-search-alt:before {
	content: "\eb55"
}

.bx-search-alt-2:before {
	content: "\eb56"
}

.bx-selection:before {
	content: "\eb57"
}

.bx-select-multiple:before {
	content: "\eb58"
}

.bx-send:before {
	content: "\eb59"
}

.bx-server:before {
	content: "\eb5a"
}

.bx-shape-circle:before {
	content: "\eb5b"
}

.bx-shape-polygon:before {
	content: "\eb5c"
}

.bx-shape-square:before {
	content: "\eb5d"
}

.bx-shape-triangle:before {
	content: "\eb5e"
}

.bx-share:before {
	content: "\eb5f"
}

.bx-share-alt:before {
	content: "\eb60"
}

.bx-shekel:before {
	content: "\eb61"
}

.bx-shield:before {
	content: "\eb62"
}

.bx-shield-alt:before {
	content: "\eb63"
}

.bx-shield-alt-2:before {
	content: "\eb64"
}

.bx-shield-quarter:before {
	content: "\eb65"
}

.bx-shield-x:before {
	content: "\eb66"
}

.bx-shocked:before {
	content: "\eb67"
}

.bx-shopping-bag:before {
	content: "\eb68"
}

.bx-show:before {
	content: "\eb69"
}

.bx-show-alt:before {
	content: "\eb6a"
}

.bx-shuffle:before {
	content: "\eb6b"
}

.bx-sidebar:before {
	content: "\eb6c"
}

.bx-sitemap:before {
	content: "\eb6d"
}

.bx-skip-next:before {
	content: "\eb6e"
}

.bx-skip-next-circle:before {
	content: "\eb6f"
}

.bx-skip-previous:before {
	content: "\eb70"
}

.bx-skip-previous-circle:before {
	content: "\eb71"
}

.bx-sleepy:before {
	content: "\eb72"
}

.bx-slider:before {
	content: "\eb73"
}

.bx-slider-alt:before {
	content: "\eb74"
}

.bx-slideshow:before {
	content: "\eb75"
}

.bx-smile:before {
	content: "\eb76"
}

.bx-sort:before {
	content: "\eb77"
}

.bx-sort-alt-2:before {
	content: "\eb78"
}

.bx-sort-a-z:before {
	content: "\eb79"
}

.bx-sort-down:before {
	content: "\eb7a"
}

.bx-sort-up:before {
	content: "\eb7b"
}

.bx-sort-z-a:before {
	content: "\eb7c"
}

.bx-spa:before {
	content: "\eb7d"
}

.bx-space-bar:before {
	content: "\eb7e"
}

.bx-spray-can:before {
	content: "\eb7f"
}

.bx-spreadsheet:before {
	content: "\eb80"
}

.bx-square:before {
	content: "\eb81"
}

.bx-square-rounded:before {
	content: "\eb82"
}

.bx-star:before {
	content: "\eb83"
}

.bx-station:before {
	content: "\eb84"
}

.bx-stats:before {
	content: "\eb85"
}

.bx-sticker:before {
	content: "\eb86"
}

.bx-stop:before {
	content: "\eb87"
}

.bx-stop-circle:before {
	content: "\eb88"
}

.bx-stopwatch:before {
	content: "\eb89"
}

.bx-store:before {
	content: "\eb8a"
}

.bx-store-alt:before {
	content: "\eb8b"
}

.bx-street-view:before {
	content: "\eb8c"
}

.bx-strikethrough:before {
	content: "\eb8d"
}

.bx-subdirectory-left:before {
	content: "\eb8e"
}

.bx-subdirectory-right:before {
	content: "\eb8f"
}

.bx-sun:before {
	content: "\eb90"
}

.bx-support:before {
	content: "\eb91"
}

.bx-swim:before {
	content: "\eb92"
}

.bx-sync:before {
	content: "\eb93"
}

.bx-tab:before {
	content: "\eb94"
}

.bx-table:before {
	content: "\eb95"
}

.bx-tachometer:before {
	content: "\eb96"
}

.bx-tag:before {
	content: "\eb97"
}

.bx-tag-alt:before {
	content: "\eb98"
}

.bx-target-lock:before {
	content: "\eb99"
}

.bx-task:before {
	content: "\eb9a"
}

.bx-task-x:before {
	content: "\eb9b"
}

.bx-taxi:before {
	content: "\eb9c"
}

.bx-tennis-ball:before {
	content: "\eb9d"
}

.bx-terminal:before {
	content: "\eb9e"
}

.bx-test-tube:before {
	content: "\eb9f"
}

.bx-text:before {
	content: "\eba0"
}

.bx-time:before {
	content: "\eba1"
}

.bx-time-five:before {
	content: "\eba2"
}

.bx-timer:before {
	content: "\eba3"
}

.bx-tired:before {
	content: "\eba4"
}

.bx-toggle-left:before {
	content: "\eba5"
}

.bx-toggle-right:before {
	content: "\eba6"
}

.bx-tone:before {
	content: "\eba7"
}

.bx-traffic-cone:before {
	content: "\eba8"
}

.bx-train:before {
	content: "\eba9"
}

.bx-transfer:before {
	content: "\ebaa"
}

.bx-transfer-alt:before {
	content: "\ebab"
}

.bx-trash:before {
	content: "\ebac"
}

.bx-trash-alt:before {
	content: "\ebad"
}

.bx-trending-down:before {
	content: "\ebae"
}

.bx-trending-up:before {
	content: "\ebaf"
}

.bx-trim:before {
	content: "\ebb0"
}

.bx-trip:before {
	content: "\ebb1"
}

.bx-trophy:before {
	content: "\ebb2"
}

.bx-tv:before {
	content: "\ebb3"
}

.bx-underline:before {
	content: "\ebb4"
}

.bx-undo:before {
	content: "\ebb5"
}

.bx-unite:before {
	content: "\ebb6"
}

.bx-unlink:before {
	content: "\ebb7"
}

.bx-up-arrow:before {
	content: "\ebb8"
}

.bx-up-arrow-alt:before {
	content: "\ebb9"
}

.bx-up-arrow-circle:before {
	content: "\ebba"
}

.bx-upload:before {
	content: "\ebbb"
}

.bx-upside-down:before {
	content: "\ebbc"
}

.bx-upvote:before {
	content: "\ebbd"
}

.bx-usb:before {
	content: "\ebbe"
}

.bx-user:before {
	content: "\ebbf"
}

.bx-user-check:before {
	content: "\ebc0"
}

.bx-user-circle:before {
	content: "\ebc1"
}

.bx-user-minus:before {
	content: "\ebc2"
}

.bx-user-pin:before {
	content: "\ebc3"
}

.bx-user-plus:before {
	content: "\ebc4"
}

.bx-user-voice:before {
	content: "\ebc5"
}

.bx-user-x:before {
	content: "\ebc6"
}

.bx-vector:before {
	content: "\ebc7"
}

.bx-vertical-center:before {
	content: "\ebc8"
}

.bx-vial:before {
	content: "\ebc9"
}

.bx-video:before {
	content: "\ebca"
}

.bx-video-off:before {
	content: "\ebcb"
}

.bx-video-plus:before {
	content: "\ebcc"
}

.bx-video-recording:before {
	content: "\ebcd"
}

.bx-voicemail:before {
	content: "\ebce"
}

.bx-volume:before {
	content: "\ebcf"
}

.bx-volume-full:before {
	content: "\ebd0"
}

.bx-volume-low:before {
	content: "\ebd1"
}

.bx-volume-mute:before {
	content: "\ebd2"
}

.bx-walk:before {
	content: "\ebd3"
}

.bx-wallet:before {
	content: "\ebd4"
}

.bx-wallet-alt:before {
	content: "\ebd5"
}

.bx-water:before {
	content: "\ebd6"
}

.bx-webcam:before {
	content: "\ebd7"
}

.bx-wifi:before {
	content: "\ebd8"
}

.bx-wifi-0:before {
	content: "\ebd9"
}

.bx-wifi-1:before {
	content: "\ebda"
}

.bx-wifi-2:before {
	content: "\ebdb"
}

.bx-wifi-off:before {
	content: "\ebdc"
}

.bx-wind:before {
	content: "\ebdd"
}

.bx-window:before {
	content: "\ebde"
}

.bx-window-alt:before {
	content: "\ebdf"
}

.bx-window-close:before {
	content: "\ebe0"
}

.bx-window-open:before {
	content: "\ebe1"
}

.bx-windows:before {
	content: "\ebe2"
}

.bx-wine:before {
	content: "\ebe3"
}

.bx-wink-smile:before {
	content: "\ebe4"
}

.bx-wink-tongue:before {
	content: "\ebe5"
}

.bx-won:before {
	content: "\ebe6"
}

.bx-world:before {
	content: "\ebe7"
}

.bx-wrench:before {
	content: "\ebe8"
}

.bx-x:before {
	content: "\ebe9"
}

.bx-x-circle:before {
	content: "\ebea"
}

.bx-yen:before {
	content: "\ebeb"
}

.bx-zoom-in:before {
	content: "\ebec"
}

.bx-zoom-out:before {
	content: "\ebed"
}

.bxs-add-to-queue:before {
	content: "\ebee"
}

.bxs-adjust:before {
	content: "\ebef"
}

.bxs-adjust-alt:before {
	content: "\ebf0"
}

.bxs-alarm:before {
	content: "\ebf1"
}

.bxs-alarm-add:before {
	content: "\ebf2"
}

.bxs-alarm-exclamation:before {
	content: "\ebf3"
}

.bxs-alarm-off:before {
	content: "\ebf4"
}

.bxs-alarm-snooze:before {
	content: "\ebf5"
}

.bxs-album:before {
	content: "\ebf6"
}

.bxs-ambulance:before {
	content: "\ebf7"
}

.bxs-analyse:before {
	content: "\ebf8"
}

.bxs-angry:before {
	content: "\ebf9"
}

.bxs-arch:before {
	content: "\ebfa"
}

.bxs-archive:before {
	content: "\ebfb"
}

.bxs-archive-in:before {
	content: "\ebfc"
}

.bxs-archive-out:before {
	content: "\ebfd"
}

.bxs-area:before {
	content: "\ebfe"
}

.bxs-arrow-from-bottom:before {
	content: "\ebff"
}

.bxs-arrow-from-left:before {
	content: "\ec00"
}

.bxs-arrow-from-right:before {
	content: "\ec01"
}

.bxs-arrow-from-top:before {
	content: "\ec02"
}

.bxs-arrow-to-bottom:before {
	content: "\ec03"
}

.bxs-arrow-to-left:before {
	content: "\ec04"
}

.bxs-arrow-to-right:before {
	content: "\ec05"
}

.bxs-arrow-to-top:before {
	content: "\ec06"
}

.bxs-award:before {
	content: "\ec07"
}

.bxs-baby-carriage:before {
	content: "\ec08"
}

.bxs-backpack:before {
	content: "\ec09"
}

.bxs-badge:before {
	content: "\ec0a"
}

.bxs-badge-check:before {
	content: "\ec0b"
}

.bxs-badge-dollar:before {
	content: "\ec0c"
}

.bxs-ball:before {
	content: "\ec0d"
}

.bxs-band-aid:before {
	content: "\ec0e"
}

.bxs-bank:before {
	content: "\ec0f"
}

.bxs-bar-chart-alt-2:before {
	content: "\ec10"
}

.bxs-bar-chart-square:before {
	content: "\ec11"
}

.bxs-barcode:before {
	content: "\ec12"
}

.bxs-baseball:before {
	content: "\ec13"
}

.bxs-basket:before {
	content: "\ec14"
}

.bxs-basketball:before {
	content: "\ec15"
}

.bxs-bath:before {
	content: "\ec16"
}

.bxs-battery:before {
	content: "\ec17"
}

.bxs-battery-charging:before {
	content: "\ec18"
}

.bxs-battery-full:before {
	content: "\ec19"
}

.bxs-battery-low:before {
	content: "\ec1a"
}

.bxs-bed:before {
	content: "\ec1b"
}

.bxs-been-here:before {
	content: "\ec1c"
}

.bxs-beer:before {
	content: "\ec1d"
}

.bxs-bell:before {
	content: "\ec1e"
}

.bxs-bell-minus:before {
	content: "\ec1f"
}

.bxs-bell-off:before {
	content: "\ec20"
}

.bxs-bell-plus:before {
	content: "\ec21"
}

.bxs-bell-ring:before {
	content: "\ec22"
}

.bxs-bible:before {
	content: "\ec23"
}

.bxs-binoculars:before {
	content: "\ec24"
}

.bxs-blanket:before {
	content: "\ec25"
}

.bxs-bolt:before {
	content: "\ec26"
}

.bxs-bolt-circle:before {
	content: "\ec27"
}

.bxs-bomb:before {
	content: "\ec28"
}

.bxs-bone:before {
	content: "\ec29"
}

.bxs-bong:before {
	content: "\ec2a"
}

.bxs-book:before {
	content: "\ec2b"
}

.bxs-book-add:before {
	content: "\ec2c"
}

.bxs-book-alt:before {
	content: "\ec2d"
}

.bxs-book-bookmark:before {
	content: "\ec2e"
}

.bxs-book-content:before {
	content: "\ec2f"
}

.bxs-book-heart:before {
	content: "\ec30"
}

.bxs-bookmark:before {
	content: "\ec31"
}

.bxs-bookmark-alt:before {
	content: "\ec32"
}

.bxs-bookmark-alt-minus:before {
	content: "\ec33"
}

.bxs-bookmark-alt-plus:before {
	content: "\ec34"
}

.bxs-bookmark-heart:before {
	content: "\ec35"
}

.bxs-bookmark-minus:before {
	content: "\ec36"
}

.bxs-bookmark-plus:before {
	content: "\ec37"
}

.bxs-bookmarks:before {
	content: "\ec38"
}

.bxs-bookmark-star:before {
	content: "\ec39"
}

.bxs-book-open:before {
	content: "\ec3a"
}

.bxs-book-reader:before {
	content: "\ec3b"
}

.bxs-bot:before {
	content: "\ec3c"
}

.bxs-bowling-ball:before {
	content: "\ec3d"
}

.bxs-box:before {
	content: "\ec3e"
}

.bxs-brain:before {
	content: "\ec3f"
}

.bxs-briefcase:before {
	content: "\ec40"
}

.bxs-briefcase-alt:before {
	content: "\ec41"
}

.bxs-briefcase-alt-2:before {
	content: "\ec42"
}

.bxs-brightness:before {
	content: "\ec43"
}

.bxs-brightness-half:before {
	content: "\ec44"
}

.bxs-brush:before {
	content: "\ec45"
}

.bxs-brush-alt:before {
	content: "\ec46"
}

.bxs-bug:before {
	content: "\ec47"
}

.bxs-bug-alt:before {
	content: "\ec48"
}

.bxs-building:before {
	content: "\ec49"
}

.bxs-building-house:before {
	content: "\ec4a"
}

.bxs-buildings:before {
	content: "\ec4b"
}

.bxs-bulb:before {
	content: "\ec4c"
}

.bxs-bullseye:before {
	content: "\ec4d"
}

.bxs-buoy:before {
	content: "\ec4e"
}

.bxs-bus:before {
	content: "\ec4f"
}

.bxs-business:before {
	content: "\ec50"
}

.bxs-bus-school:before {
	content: "\ec51"
}

.bxs-cabinet:before {
	content: "\ec52"
}

.bxs-cake:before {
	content: "\ec53"
}

.bxs-calculator:before {
	content: "\ec54"
}

.bxs-calendar:before {
	content: "\ec55"
}

.bxs-calendar-alt:before {
	content: "\ec56"
}

.bxs-calendar-check:before {
	content: "\ec57"
}

.bxs-calendar-edit:before {
	content: "\ec58"
}

.bxs-calendar-event:before {
	content: "\ec59"
}

.bxs-calendar-exclamation:before {
	content: "\ec5a"
}

.bxs-calendar-heart:before {
	content: "\ec5b"
}

.bxs-calendar-minus:before {
	content: "\ec5c"
}

.bxs-calendar-plus:before {
	content: "\ec5d"
}

.bxs-calendar-star:before {
	content: "\ec5e"
}

.bxs-calendar-week:before {
	content: "\ec5f"
}

.bxs-calendar-x:before {
	content: "\ec60"
}

.bxs-camera:before {
	content: "\ec61"
}

.bxs-camera-home:before {
	content: "\ec62"
}

.bxs-camera-movie:before {
	content: "\ec63"
}

.bxs-camera-off:before {
	content: "\ec64"
}

.bxs-camera-plus:before {
	content: "\ec65"
}

.bxs-capsule:before {
	content: "\ec66"
}

.bxs-captions:before {
	content: "\ec67"
}

.bxs-car:before {
	content: "\ec68"
}

.bxs-car-battery:before {
	content: "\ec69"
}

.bxs-car-crash:before {
	content: "\ec6a"
}

.bxs-card:before {
	content: "\ec6b"
}

.bxs-caret-down-circle:before {
	content: "\ec6c"
}

.bxs-caret-down-square:before {
	content: "\ec6d"
}

.bxs-caret-left-circle:before {
	content: "\ec6e"
}

.bxs-caret-left-square:before {
	content: "\ec6f"
}

.bxs-caret-right-circle:before {
	content: "\ec70"
}

.bxs-caret-right-square:before {
	content: "\ec71"
}

.bxs-caret-up-circle:before {
	content: "\ec72"
}

.bxs-caret-up-square:before {
	content: "\ec73"
}

.bxs-car-garage:before {
	content: "\ec74"
}

.bxs-car-mechanic:before {
	content: "\ec75"
}

.bxs-carousel:before {
	content: "\ec76"
}

.bxs-cart:before {
	content: "\ec77"
}

.bxs-cart-add:before {
	content: "\ec78"
}

.bxs-cart-alt:before {
	content: "\ec79"
}

.bxs-cart-download:before {
	content: "\ec7a"
}

.bxs-car-wash:before {
	content: "\ec7b"
}

.bxs-category:before {
	content: "\ec7c"
}

.bxs-category-alt:before {
	content: "\ec7d"
}

.bxs-cctv:before {
	content: "\ec7e"
}

.bxs-certification:before {
	content: "\ec7f"
}

.bxs-chalkboard:before {
	content: "\ec80"
}

.bxs-chart:before {
	content: "\ec81"
}

.bxs-chat:before {
	content: "\ec82"
}

.bxs-checkbox:before {
	content: "\ec83"
}

.bxs-checkbox-checked:before {
	content: "\ec84"
}

.bxs-check-circle:before {
	content: "\ec85"
}

.bxs-check-shield:before {
	content: "\ec86"
}

.bxs-check-square:before {
	content: "\ec87"
}

.bxs-chess:before {
	content: "\ec88"
}

.bxs-chevron-down:before {
	content: "\ec89"
}

.bxs-chevron-down-circle:before {
	content: "\ec8a"
}

.bxs-chevron-down-square:before {
	content: "\ec8b"
}

.bxs-chevron-left:before {
	content: "\ec8c"
}

.bxs-chevron-left-circle:before {
	content: "\ec8d"
}

.bxs-chevron-left-square:before {
	content: "\ec8e"
}

.bxs-chevron-right:before {
	content: "\ec8f"
}

.bxs-chevron-right-circle:before {
	content: "\ec90"
}

.bxs-chevron-right-square:before {
	content: "\ec91"
}

.bxs-chevrons-down:before {
	content: "\ec92"
}

.bxs-chevrons-left:before {
	content: "\ec93"
}

.bxs-chevrons-right:before {
	content: "\ec94"
}

.bxs-chevrons-up:before {
	content: "\ec95"
}

.bxs-chevron-up:before {
	content: "\ec96"
}

.bxs-chevron-up-circle:before {
	content: "\ec97"
}

.bxs-chevron-up-square:before {
	content: "\ec98"
}

.bxs-chip:before {
	content: "\ec99"
}

.bxs-church:before {
	content: "\ec9a"
}

.bxs-circle:before {
	content: "\ec9b"
}

.bxs-city:before {
	content: "\ec9c"
}

.bxs-clinic:before {
	content: "\ec9d"
}

.bxs-cloud:before {
	content: "\ec9e"
}

.bxs-cloud-download:before {
	content: "\ec9f"
}

.bxs-cloud-lightning:before {
	content: "\eca0"
}

.bxs-cloud-rain:before {
	content: "\eca1"
}

.bxs-cloud-upload:before {
	content: "\eca2"
}

.bxs-coffee:before {
	content: "\eca3"
}

.bxs-coffee-alt:before {
	content: "\eca4"
}

.bxs-coffee-togo:before {
	content: "\eca5"
}

.bxs-cog:before {
	content: "\eca6"
}

.bxs-coin:before {
	content: "\eca7"
}

.bxs-coin-stack:before {
	content: "\eca8"
}

.bxs-collection:before {
	content: "\eca9"
}

.bxs-color-fill:before {
	content: "\ecaa"
}

.bxs-comment:before {
	content: "\ecab"
}

.bxs-comment-add:before {
	content: "\ecac"
}

.bxs-comment-check:before {
	content: "\ecad"
}

.bxs-comment-detail:before {
	content: "\ecae"
}

.bxs-comment-dots:before {
	content: "\ecaf"
}

.bxs-comment-edit:before {
	content: "\ecb0"
}

.bxs-comment-error:before {
	content: "\ecb1"
}

.bxs-comment-minus:before {
	content: "\ecb2"
}

.bxs-comment-x:before {
	content: "\ecb3"
}

.bxs-compass:before {
	content: "\ecb4"
}

.bxs-component:before {
	content: "\ecb5"
}

.bxs-confused:before {
	content: "\ecb6"
}

.bxs-contact:before {
	content: "\ecb7"
}

.bxs-conversation:before {
	content: "\ecb8"
}

.bxs-cookie:before {
	content: "\ecb9"
}

.bxs-cool:before {
	content: "\ecba"
}

.bxs-copy:before {
	content: "\ecbb"
}

.bxs-copy-alt:before {
	content: "\ecbc"
}

.bxs-copyright:before {
	content: "\ecbd"
}

.bxs-coupon:before {
	content: "\ecbe"
}

.bxs-credit-card:before {
	content: "\ecbf"
}

.bxs-credit-card-alt:before {
	content: "\ecc0"
}

.bxs-credit-card-front:before {
	content: "\ecc1"
}

.bxs-crop:before {
	content: "\ecc2"
}

.bxs-crown:before {
	content: "\ecc3"
}

.bxs-cube:before {
	content: "\ecc4"
}

.bxs-cube-alt:before {
	content: "\ecc5"
}

.bxs-cuboid:before {
	content: "\ecc6"
}

.bxs-customize:before {
	content: "\ecc7"
}

.bxs-cylinder:before {
	content: "\ecc8"
}

.bxs-dashboard:before {
	content: "\ecc9"
}

.bxs-data:before {
	content: "\ecca"
}

.bxs-detail:before {
	content: "\eccb"
}

.bxs-devices:before {
	content: "\eccc"
}

.bxs-diamond:before {
	content: "\eccd"
}

.bxs-dice-1:before {
	content: "\ecce"
}

.bxs-dice-2:before {
	content: "\eccf"
}

.bxs-dice-3:before {
	content: "\ecd0"
}

.bxs-dice-4:before {
	content: "\ecd1"
}

.bxs-dice-5:before {
	content: "\ecd2"
}

.bxs-dice-6:before {
	content: "\ecd3"
}

.bxs-direction-left:before {
	content: "\ecd4"
}

.bxs-direction-right:before {
	content: "\ecd5"
}

.bxs-directions:before {
	content: "\ecd6"
}

.bxs-disc:before {
	content: "\ecd7"
}

.bxs-discount:before {
	content: "\ecd8"
}

.bxs-dish:before {
	content: "\ecd9"
}

.bxs-dislike:before {
	content: "\ecda"
}

.bxs-dizzy:before {
	content: "\ecdb"
}

.bxs-dock-bottom:before {
	content: "\ecdc"
}

.bxs-dock-left:before {
	content: "\ecdd"
}

.bxs-dock-right:before {
	content: "\ecde"
}

.bxs-dock-top:before {
	content: "\ecdf"
}

.bxs-dollar-circle:before {
	content: "\ece0"
}

.bxs-donate-blood:before {
	content: "\ece1"
}

.bxs-donate-heart:before {
	content: "\ece2"
}

.bxs-door-open:before {
	content: "\ece3"
}

.bxs-doughnut-chart:before {
	content: "\ece4"
}

.bxs-down-arrow:before {
	content: "\ece5"
}

.bxs-down-arrow-alt:before {
	content: "\ece6"
}

.bxs-down-arrow-circle:before {
	content: "\ece7"
}

.bxs-down-arrow-square:before {
	content: "\ece8"
}

.bxs-download:before {
	content: "\ece9"
}

.bxs-downvote:before {
	content: "\ecea"
}

.bxs-drink:before {
	content: "\eceb"
}

.bxs-droplet:before {
	content: "\ecec"
}

.bxs-droplet-half:before {
	content: "\eced"
}

.bxs-dryer:before {
	content: "\ecee"
}

.bxs-duplicate:before {
	content: "\ecef"
}

.bxs-edit:before {
	content: "\ecf0"
}

.bxs-edit-alt:before {
	content: "\ecf1"
}

.bxs-edit-location:before {
	content: "\ecf2"
}

.bxs-eject:before {
	content: "\ecf3"
}

.bxs-envelope:before {
	content: "\ecf4"
}

.bxs-envelope-open:before {
	content: "\ecf5"
}

.bxs-eraser:before {
	content: "\ecf6"
}

.bxs-error:before {
	content: "\ecf7"
}

.bxs-error-alt:before {
	content: "\ecf8"
}

.bxs-error-circle:before {
	content: "\ecf9"
}

.bxs-ev-station:before {
	content: "\ecfa"
}

.bxs-exit:before {
	content: "\ecfb"
}

.bxs-extension:before {
	content: "\ecfc"
}

.bxs-eyedropper:before {
	content: "\ecfd"
}

.bxs-face:before {
	content: "\ecfe"
}

.bxs-face-mask:before {
	content: "\ecff"
}

.bxs-factory:before {
	content: "\ed00"
}

.bxs-fast-forward-circle:before {
	content: "\ed01"
}

.bxs-file:before {
	content: "\ed02"
}

.bxs-file-archive:before {
	content: "\ed03"
}

.bxs-file-blank:before {
	content: "\ed04"
}

.bxs-file-css:before {
	content: "\ed05"
}

.bxs-file-doc:before {
	content: "\ed06"
}

.bxs-file-export:before {
	content: "\ed07"
}

.bxs-file-find:before {
	content: "\ed08"
}

.bxs-file-gif:before {
	content: "\ed09"
}

.bxs-file-html:before {
	content: "\ed0a"
}

.bxs-file-image:before {
	content: "\ed0b"
}

.bxs-file-import:before {
	content: "\ed0c"
}

.bxs-file-jpg:before {
	content: "\ed0d"
}

.bxs-file-js:before {
	content: "\ed0e"
}

.bxs-file-json:before {
	content: "\ed0f"
}

.bxs-file-md:before {
	content: "\ed10"
}

.bxs-file-pdf:before {
	content: "\ed11"
}

.bxs-file-plus:before {
	content: "\ed12"
}

.bxs-file-png:before {
	content: "\ed13"
}

.bxs-file-txt:before {
	content: "\ed14"
}

.bxs-film:before {
	content: "\ed15"
}

.bxs-filter-alt:before {
	content: "\ed16"
}

.bxs-first-aid:before {
	content: "\ed17"
}

.bxs-flag:before {
	content: "\ed18"
}

.bxs-flag-alt:before {
	content: "\ed19"
}

.bxs-flag-checkered:before {
	content: "\ed1a"
}

.bxs-flame:before {
	content: "\ed1b"
}

.bxs-flask:before {
	content: "\ed1c"
}

.bxs-florist:before {
	content: "\ed1d"
}

.bxs-folder:before {
	content: "\ed1e"
}

.bxs-folder-minus:before {
	content: "\ed1f"
}

.bxs-folder-open:before {
	content: "\ed20"
}

.bxs-folder-plus:before {
	content: "\ed21"
}

.bxs-food-menu:before {
	content: "\ed22"
}

.bxs-fridge:before {
	content: "\ed23"
}

.bxs-game:before {
	content: "\ed24"
}

.bxs-gas-pump:before {
	content: "\ed25"
}

.bxs-ghost:before {
	content: "\ed26"
}

.bxs-gift:before {
	content: "\ed27"
}

.bxs-graduation:before {
	content: "\ed28"
}

.bxs-grid:before {
	content: "\ed29"
}

.bxs-grid-alt:before {
	content: "\ed2a"
}

.bxs-group:before {
	content: "\ed2b"
}

.bxs-guitar-amp:before {
	content: "\ed2c"
}

.bxs-hand-down:before {
	content: "\ed2d"
}

.bxs-hand-left:before {
	content: "\ed2e"
}

.bxs-hand-right:before {
	content: "\ed2f"
}

.bxs-hand-up:before {
	content: "\ed30"
}

.bxs-happy:before {
	content: "\ed31"
}

.bxs-happy-alt:before {
	content: "\ed32"
}

.bxs-happy-beaming:before {
	content: "\ed33"
}

.bxs-happy-heart-eyes:before {
	content: "\ed34"
}

.bxs-hdd:before {
	content: "\ed35"
}

.bxs-heart:before {
	content: "\ed36"
}

.bxs-heart-circle:before {
	content: "\ed37"
}

.bxs-heart-square:before {
	content: "\ed38"
}

.bxs-help-circle:before {
	content: "\ed39"
}

.bxs-hide:before {
	content: "\ed3a"
}

.bxs-home:before {
	content: "\ed3b"
}

.bxs-home-circle:before {
	content: "\ed3c"
}

.bxs-home-heart:before {
	content: "\ed3d"
}

.bxs-home-smile:before {
	content: "\ed3e"
}

.bxs-hot:before {
	content: "\ed3f"
}

.bxs-hotel:before {
	content: "\ed40"
}

.bxs-hourglass:before {
	content: "\ed41"
}

.bxs-hourglass-bottom:before {
	content: "\ed42"
}

.bxs-hourglass-top:before {
	content: "\ed43"
}

.bxs-id-card:before {
	content: "\ed44"
}

.bxs-image:before {
	content: "\ed45"
}

.bxs-image-add:before {
	content: "\ed46"
}

.bxs-image-alt:before {
	content: "\ed47"
}

.bxs-inbox:before {
	content: "\ed48"
}

.bxs-info-circle:before {
	content: "\ed49"
}

.bxs-info-square:before {
	content: "\ed4a"
}

.bxs-institution:before {
	content: "\ed4b"
}

.bxs-joystick:before {
	content: "\ed4c"
}

.bxs-joystick-alt:before {
	content: "\ed4d"
}

.bxs-joystick-button:before {
	content: "\ed4e"
}

.bxs-key:before {
	content: "\ed4f"
}

.bxs-keyboard:before {
	content: "\ed50"
}

.bxs-label:before {
	content: "\ed51"
}

.bxs-landmark:before {
	content: "\ed52"
}

.bxs-landscape:before {
	content: "\ed53"
}

.bxs-laugh:before {
	content: "\ed54"
}

.bxs-layer:before {
	content: "\ed55"
}

.bxs-layer-minus:before {
	content: "\ed56"
}

.bxs-layer-plus:before {
	content: "\ed57"
}

.bxs-layout:before {
	content: "\ed58"
}

.bxs-left-arrow:before {
	content: "\ed59"
}

.bxs-left-arrow-alt:before {
	content: "\ed5a"
}

.bxs-left-arrow-circle:before {
	content: "\ed5b"
}

.bxs-left-arrow-square:before {
	content: "\ed5c"
}

.bxs-left-down-arrow-circle:before {
	content: "\ed5d"
}

.bxs-left-top-arrow-circle:before {
	content: "\ed5e"
}

.bxs-like:before {
	content: "\ed5f"
}

.bxs-location-plus:before {
	content: "\ed60"
}

.bxs-lock:before {
	content: "\ed61"
}

.bxs-lock-alt:before {
	content: "\ed62"
}

.bxs-lock-open:before {
	content: "\ed63"
}

.bxs-lock-open-alt:before {
	content: "\ed64"
}

.bxs-log-in:before {
	content: "\ed65"
}

.bxs-log-in-circle:before {
	content: "\ed66"
}

.bxs-log-out:before {
	content: "\ed67"
}

.bxs-log-out-circle:before {
	content: "\ed68"
}

.bxs-low-vision:before {
	content: "\ed69"
}

.bxs-magic-wand:before {
	content: "\ed6a"
}

.bxs-magnet:before {
	content: "\ed6b"
}

.bxs-map:before {
	content: "\ed6c"
}

.bxs-map-alt:before {
	content: "\ed6d"
}

.bxs-map-pin:before {
	content: "\ed6e"
}

.bxs-mask:before {
	content: "\ed6f"
}

.bxs-medal:before {
	content: "\ed70"
}

.bxs-megaphone:before {
	content: "\ed71"
}

.bxs-meh:before {
	content: "\ed72"
}

.bxs-meh-alt:before {
	content: "\ed73"
}

.bxs-meh-blank:before {
	content: "\ed74"
}

.bxs-memory-card:before {
	content: "\ed75"
}

.bxs-message:before {
	content: "\ed76"
}

.bxs-message-add:before {
	content: "\ed77"
}

.bxs-message-alt:before {
	content: "\ed78"
}

.bxs-message-alt-add:before {
	content: "\ed79"
}

.bxs-message-alt-check:before {
	content: "\ed7a"
}

.bxs-message-alt-detail:before {
	content: "\ed7b"
}

.bxs-message-alt-dots:before {
	content: "\ed7c"
}

.bxs-message-alt-edit:before {
	content: "\ed7d"
}

.bxs-message-alt-error:before {
	content: "\ed7e"
}

.bxs-message-alt-minus:before {
	content: "\ed7f"
}

.bxs-message-alt-x:before {
	content: "\ed80"
}

.bxs-message-check:before {
	content: "\ed81"
}

.bxs-message-detail:before {
	content: "\ed82"
}

.bxs-message-dots:before {
	content: "\ed83"
}

.bxs-message-edit:before {
	content: "\ed84"
}

.bxs-message-error:before {
	content: "\ed85"
}

.bxs-message-minus:before {
	content: "\ed86"
}

.bxs-message-rounded:before {
	content: "\ed87"
}

.bxs-message-rounded-add:before {
	content: "\ed88"
}

.bxs-message-rounded-check:before {
	content: "\ed89"
}

.bxs-message-rounded-detail:before {
	content: "\ed8a"
}

.bxs-message-rounded-dots:before {
	content: "\ed8b"
}

.bxs-message-rounded-edit:before {
	content: "\ed8c"
}

.bxs-message-rounded-error:before {
	content: "\ed8d"
}

.bxs-message-rounded-minus:before {
	content: "\ed8e"
}

.bxs-message-rounded-x:before {
	content: "\ed8f"
}

.bxs-message-square:before {
	content: "\ed90"
}

.bxs-message-square-add:before {
	content: "\ed91"
}

.bxs-message-square-check:before {
	content: "\ed92"
}

.bxs-message-square-detail:before {
	content: "\ed93"
}

.bxs-message-square-dots:before {
	content: "\ed94"
}

.bxs-message-square-edit:before {
	content: "\ed95"
}

.bxs-message-square-error:before {
	content: "\ed96"
}

.bxs-message-square-minus:before {
	content: "\ed97"
}

.bxs-message-square-x:before {
	content: "\ed98"
}

.bxs-message-x:before {
	content: "\ed99"
}

.bxs-meteor:before {
	content: "\ed9a"
}

.bxs-microchip:before {
	content: "\ed9b"
}

.bxs-microphone:before {
	content: "\ed9c"
}

.bxs-microphone-alt:before {
	content: "\ed9d"
}

.bxs-microphone-off:before {
	content: "\ed9e"
}

.bxs-minus-circle:before {
	content: "\ed9f"
}

.bxs-minus-square:before {
	content: "\eda0"
}

.bxs-mobile:before {
	content: "\eda1"
}

.bxs-mobile-vibration:before {
	content: "\eda2"
}

.bxs-moon:before {
	content: "\eda3"
}

.bxs-mouse:before {
	content: "\eda4"
}

.bxs-mouse-alt:before {
	content: "\eda5"
}

.bxs-movie:before {
	content: "\eda6"
}

.bxs-movie-play:before {
	content: "\eda7"
}

.bxs-music:before {
	content: "\eda8"
}

.bxs-navigation:before {
	content: "\eda9"
}

.bxs-network-chart:before {
	content: "\edaa"
}

.bxs-news:before {
	content: "\edab"
}

.bxs-no-entry:before {
	content: "\edac"
}

.bxs-note:before {
	content: "\edad"
}

.bxs-notepad:before {
	content: "\edae"
}

.bxs-notification:before {
	content: "\edaf"
}

.bxs-notification-off:before {
	content: "\edb0"
}

.bxs-offer:before {
	content: "\edb1"
}

.bxs-package:before {
	content: "\edb2"
}

.bxs-paint:before {
	content: "\edb3"
}

.bxs-paint-roll:before {
	content: "\edb4"
}

.bxs-palette:before {
	content: "\edb5"
}

.bxs-paper-plane:before {
	content: "\edb6"
}

.bxs-parking:before {
	content: "\edb7"
}

.bxs-paste:before {
	content: "\edb8"
}

.bxs-pen:before {
	content: "\edb9"
}

.bxs-pencil:before {
	content: "\edba"
}

.bxs-phone:before {
	content: "\edbb"
}

.bxs-phone-call:before {
	content: "\edbc"
}

.bxs-phone-incoming:before {
	content: "\edbd"
}

.bxs-phone-outgoing:before {
	content: "\edbe"
}

.bxs-photo-album:before {
	content: "\edbf"
}

.bxs-piano:before {
	content: "\edc0"
}

.bxs-pie-chart:before {
	content: "\edc1"
}

.bxs-pie-chart-alt:before {
	content: "\edc2"
}

.bxs-pie-chart-alt-2:before {
	content: "\edc3"
}

.bxs-pin:before {
	content: "\edc4"
}

.bxs-pizza:before {
	content: "\edc5"
}

.bxs-plane:before {
	content: "\edc6"
}

.bxs-plane-alt:before {
	content: "\edc7"
}

.bxs-plane-land:before {
	content: "\edc8"
}

.bxs-planet:before {
	content: "\edc9"
}

.bxs-plane-take-off:before {
	content: "\edca"
}

.bxs-playlist:before {
	content: "\edcb"
}

.bxs-plug:before {
	content: "\edcc"
}

.bxs-plus-circle:before {
	content: "\edcd"
}

.bxs-plus-square:before {
	content: "\edce"
}

.bxs-pointer:before {
	content: "\edcf"
}

.bxs-polygon:before {
	content: "\edd0"
}

.bxs-printer:before {
	content: "\edd1"
}

.bxs-purchase-tag:before {
	content: "\edd2"
}

.bxs-purchase-tag-alt:before {
	content: "\edd3"
}

.bxs-pyramid:before {
	content: "\edd4"
}

.bxs-quote-alt-left:before {
	content: "\edd5"
}

.bxs-quote-alt-right:before {
	content: "\edd6"
}

.bxs-quote-left:before {
	content: "\edd7"
}

.bxs-quote-right:before {
	content: "\edd8"
}

.bxs-quote-single-left:before {
	content: "\edd9"
}

.bxs-quote-single-right:before {
	content: "\edda"
}

.bxs-radiation:before {
	content: "\eddb"
}

.bxs-radio:before {
	content: "\eddc"
}

.bxs-receipt:before {
	content: "\eddd"
}

.bxs-rectangle:before {
	content: "\edde"
}

.bxs-rename:before {
	content: "\eddf"
}

.bxs-report:before {
	content: "\ede0"
}

.bxs-rewind-circle:before {
	content: "\ede1"
}

.bxs-right-arrow:before {
	content: "\ede2"
}

.bxs-right-arrow-alt:before {
	content: "\ede3"
}

.bxs-right-arrow-circle:before {
	content: "\ede4"
}

.bxs-right-arrow-square:before {
	content: "\ede5"
}

.bxs-right-down-arrow-circle:before {
	content: "\ede6"
}

.bxs-right-top-arrow-circle:before {
	content: "\ede7"
}

.bxs-rocket:before {
	content: "\ede8"
}

.bxs-ruler:before {
	content: "\ede9"
}

.bxs-sad:before {
	content: "\edea"
}

.bxs-save:before {
	content: "\edeb"
}

.bxs-school:before {
	content: "\edec"
}

.bxs-search:before {
	content: "\eded"
}

.bxs-search-alt-2:before {
	content: "\edee"
}

.bxs-select-multiple:before {
	content: "\edef"
}

.bxs-send:before {
	content: "\edf0"
}

.bxs-server:before {
	content: "\edf1"
}

.bxs-shapes:before {
	content: "\edf2"
}

.bxs-share:before {
	content: "\edf3"
}

.bxs-share-alt:before {
	content: "\edf4"
}

.bxs-shield:before {
	content: "\edf5"
}

.bxs-shield-alt-2:before {
	content: "\edf6"
}

.bxs-shield-x:before {
	content: "\edf7"
}

.bxs-ship:before {
	content: "\edf8"
}

.bxs-shocked:before {
	content: "\edf9"
}

.bxs-shopping-bag:before {
	content: "\edfa"
}

.bxs-shopping-bag-alt:before {
	content: "\edfb"
}

.bxs-shopping-bags:before {
	content: "\edfc"
}

.bxs-show:before {
	content: "\edfd"
}

.bxs-skip-next-circle:before {
	content: "\edfe"
}

.bxs-skip-previous-circle:before {
	content: "\edff"
}

.bxs-skull:before {
	content: "\ee00"
}

.bxs-sleepy:before {
	content: "\ee01"
}

.bxs-slideshow:before {
	content: "\ee02"
}

.bxs-smile:before {
	content: "\ee03"
}

.bxs-sort-alt:before {
	content: "\ee04"
}

.bxs-spa:before {
	content: "\ee05"
}

.bxs-spray-can:before {
	content: "\ee06"
}

.bxs-spreadsheet:before {
	content: "\ee07"
}

.bxs-square:before {
	content: "\ee08"
}

.bxs-square-rounded:before {
	content: "\ee09"
}

.bxs-star:before {
	content: "\ee0a"
}

.bxs-star-half:before {
	content: "\ee0b"
}

.bxs-sticker:before {
	content: "\ee0c"
}

.bxs-stopwatch:before {
	content: "\ee0d"
}

.bxs-store:before {
	content: "\ee0e"
}

.bxs-store-alt:before {
	content: "\ee0f"
}

.bxs-sun:before {
	content: "\ee10"
}

.bxs-tachometer:before {
	content: "\ee11"
}

.bxs-tag:before {
	content: "\ee12"
}

.bxs-tag-alt:before {
	content: "\ee13"
}

.bxs-tag-x:before {
	content: "\ee14"
}

.bxs-taxi:before {
	content: "\ee15"
}

.bxs-tennis-ball:before {
	content: "\ee16"
}

.bxs-terminal:before {
	content: "\ee17"
}

.bxs-thermometer:before {
	content: "\ee18"
}

.bxs-time:before {
	content: "\ee19"
}

.bxs-time-five:before {
	content: "\ee1a"
}

.bxs-timer:before {
	content: "\ee1b"
}

.bxs-tired:before {
	content: "\ee1c"
}

.bxs-toggle-left:before {
	content: "\ee1d"
}

.bxs-toggle-right:before {
	content: "\ee1e"
}

.bxs-tone:before {
	content: "\ee1f"
}

.bxs-torch:before {
	content: "\ee20"
}

.bxs-to-top:before {
	content: "\ee21"
}

.bxs-traffic:before {
	content: "\ee22"
}

.bxs-traffic-barrier:before {
	content: "\ee23"
}

.bxs-traffic-cone:before {
	content: "\ee24"
}

.bxs-train:before {
	content: "\ee25"
}

.bxs-trash:before {
	content: "\ee26"
}

.bxs-trash-alt:before {
	content: "\ee27"
}

.bxs-tree:before {
	content: "\ee28"
}

.bxs-trophy:before {
	content: "\ee29"
}

.bxs-truck:before {
	content: "\ee2a"
}

.bxs-t-shirt:before {
	content: "\ee2b"
}

.bxs-tv:before {
	content: "\ee2c"
}

.bxs-up-arrow:before {
	content: "\ee2d"
}

.bxs-up-arrow-alt:before {
	content: "\ee2e"
}

.bxs-up-arrow-circle:before {
	content: "\ee2f"
}

.bxs-up-arrow-square:before {
	content: "\ee30"
}

.bxs-upside-down:before {
	content: "\ee31"
}

.bxs-upvote:before {
	content: "\ee32"
}

.bxs-user:before {
	content: "\ee33"
}

.bxs-user-account:before {
	content: "\ee34"
}

.bxs-user-badge:before {
	content: "\ee35"
}

.bxs-user-check:before {
	content: "\ee36"
}

.bxs-user-circle:before {
	content: "\ee37"
}

.bxs-user-detail:before {
	content: "\ee38"
}

.bxs-user-minus:before {
	content: "\ee39"
}

.bxs-user-pin:before {
	content: "\ee3a"
}

.bxs-user-plus:before {
	content: "\ee3b"
}

.bxs-user-rectangle:before {
	content: "\ee3c"
}

.bxs-user-voice:before {
	content: "\ee3d"
}

.bxs-user-x:before {
	content: "\ee3e"
}

.bxs-vector:before {
	content: "\ee3f"
}

.bxs-vial:before {
	content: "\ee40"
}

.bxs-video:before {
	content: "\ee41"
}

.bxs-video-off:before {
	content: "\ee42"
}

.bxs-video-plus:before {
	content: "\ee43"
}

.bxs-video-recording:before {
	content: "\ee44"
}

.bxs-videos:before {
	content: "\ee45"
}

.bxs-virus:before {
	content: "\ee46"
}

.bxs-virus-block:before {
	content: "\ee47"
}

.bxs-volume:before {
	content: "\ee48"
}

.bxs-volume-full:before {
	content: "\ee49"
}

.bxs-volume-low:before {
	content: "\ee4a"
}

.bxs-volume-mute:before {
	content: "\ee4b"
}

.bxs-wallet:before {
	content: "\ee4c"
}

.bxs-wallet-alt:before {
	content: "\ee4d"
}

.bxs-washer:before {
	content: "\ee4e"
}

.bxs-watch:before {
	content: "\ee4f"
}

.bxs-watch-alt:before {
	content: "\ee50"
}

.bxs-webcam:before {
	content: "\ee51"
}

.bxs-widget:before {
	content: "\ee52"
}

.bxs-window-alt:before {
	content: "\ee53"
}

.bxs-wine:before {
	content: "\ee54"
}

.bxs-wink-smile:before {
	content: "\ee55"
}

.bxs-wink-tongue:before {
	content: "\ee56"
}

.bxs-wrench:before {
	content: "\ee57"
}

.bxs-x-circle:before {
	content: "\ee58"
}

.bxs-x-square:before {
	content: "\ee59"
}

.bxs-yin-yang:before {
	content: "\ee5a"
}

.bxs-zap:before {
	content: "\ee5b"
}

.bxs-zoom-in:before {
	content: "\ee5c"
}

.bxs-zoom-out:before {
	content: "\ee5d"
}

.bxl-500px:before {
	content: "\ee5e"
}

.bxl-adobe:before {
	content: "\ee5f"
}

.bxl-airbnb:before {
	content: "\ee60"
}

.bxl-algolia:before {
	content: "\ee61"
}

.bxl-amazon:before {
	content: "\ee62"
}

.bxl-android:before {
	content: "\ee63"
}

.bxl-angular:before {
	content: "\ee64"
}

.bxl-apple:before {
	content: "\ee65"
}

.bxl-audible:before {
	content: "\ee66"
}

.bxl-baidu:before {
	content: "\ee67"
}

.bxl-behance:before {
	content: "\ee68"
}

.bxl-bing:before {
	content: "\ee69"
}

.bxl-bitcoin:before {
	content: "\ee6a"
}

.bxl-blender:before {
	content: "\ee6b"
}

.bxl-blogger:before {
	content: "\ee6c"
}

.bxl-bootstrap:before {
	content: "\ee6d"
}

.bxl-chrome:before {
	content: "\ee6e"
}

.bxl-codepen:before {
	content: "\ee6f"
}

.bxl-c-plus-plus:before {
	content: "\ee70"
}

.bxl-creative-commons:before {
	content: "\ee71"
}

.bxl-css3:before {
	content: "\ee72"
}

.bxl-dailymotion:before {
	content: "\ee73"
}

.bxl-deviantart:before {
	content: "\ee74"
}

.bxl-dev-to:before {
	content: "\ee75"
}

.bxl-digg:before {
	content: "\ee76"
}

.bxl-digitalocean:before {
	content: "\ee77"
}

.bxl-discord:before {
	content: "\ee78"
}

.bxl-discourse:before {
	content: "\ee79"
}

.bxl-django:before {
	content: "\ee7a"
}

.bxl-dribbble:before {
	content: "\ee7b"
}

.bxl-dropbox:before {
	content: "\ee7c"
}

.bxl-drupal:before {
	content: "\ee7d"
}

.bxl-ebay:before {
	content: "\ee7e"
}

.bxl-edge:before {
	content: "\ee7f"
}

.bxl-etsy:before {
	content: "\ee80"
}

.bxl-facebook:before {
	content: "\ee81"
}

.bxl-facebook-circle:before {
	content: "\ee82"
}

.bxl-facebook-square:before {
	content: "\ee83"
}

.bxl-figma:before {
	content: "\ee84"
}

.bxl-firebase:before {
	content: "\ee85"
}

.bxl-firefox:before {
	content: "\ee86"
}

.bxl-flickr:before {
	content: "\ee87"
}

.bxl-flickr-square:before {
	content: "\ee88"
}

.bxl-foursquare:before {
	content: "\ee89"
}

.bxl-git:before {
	content: "\ee8a"
}

.bxl-github:before {
	content: "\ee8b"
}

.bxl-gitlab:before {
	content: "\ee8c"
}

.bxl-google:before {
	content: "\ee8d"
}

.bxl-google-cloud:before {
	content: "\ee8e"
}

.bxl-google-plus:before {
	content: "\ee8f"
}

.bxl-google-plus-circle:before {
	content: "\ee90"
}

.bxl-html5:before {
	content: "\ee91"
}

.bxl-imdb:before {
	content: "\ee92"
}

.bxl-instagram:before {
	content: "\ee93"
}

.bxl-instagram-alt:before {
	content: "\ee94"
}

.bxl-internet-explorer:before {
	content: "\ee95"
}

.bxl-invision:before {
	content: "\ee96"
}

.bxl-javascript:before {
	content: "\ee97"
}

.bxl-joomla:before {
	content: "\ee98"
}

.bxl-jquery:before {
	content: "\ee99"
}

.bxl-jsfiddle:before {
	content: "\ee9a"
}

.bxl-kickstarter:before {
	content: "\ee9b"
}

.bxl-kubernetes:before {
	content: "\ee9c"
}

.bxl-less:before {
	content: "\ee9d"
}

.bxl-linkedin:before {
	content: "\ee9e"
}

.bxl-linkedin-square:before {
	content: "\ee9f"
}

.bxl-magento:before {
	content: "\eea0"
}

.bxl-mailchimp:before {
	content: "\eea1"
}

.bxl-markdown:before {
	content: "\eea2"
}

.bxl-mastercard:before {
	content: "\eea3"
}

.bxl-medium:before {
	content: "\eea4"
}

.bxl-medium-old:before {
	content: "\eea5"
}

.bxl-medium-square:before {
	content: "\eea6"
}

.bxl-messenger:before {
	content: "\eea7"
}

.bxl-microsoft:before {
	content: "\eea8"
}

.bxl-microsoft-teams:before {
	content: "\eea9"
}

.bxl-nodejs:before {
	content: "\eeaa"
}

.bxl-ok-ru:before {
	content: "\eeab"
}

.bxl-opera:before {
	content: "\eeac"
}

.bxl-patreon:before {
	content: "\eead"
}

.bxl-paypal:before {
	content: "\eeae"
}

.bxl-periscope:before {
	content: "\eeaf"
}

.bxl-pinterest:before {
	content: "\eeb0"
}

.bxl-pinterest-alt:before {
	content: "\eeb1"
}

.bxl-play-store:before {
	content: "\eeb2"
}

.bxl-pocket:before {
	content: "\eeb3"
}

.bxl-product-hunt:before {
	content: "\eeb4"
}

.bxl-python:before {
	content: "\eeb5"
}

.bxl-quora:before {
	content: "\eeb6"
}

.bxl-react:before {
	content: "\eeb7"
}

.bxl-redbubble:before {
	content: "\eeb8"
}

.bxl-reddit:before {
	content: "\eeb9"
}

.bxl-redux:before {
	content: "\eeba"
}

.bxl-sass:before {
	content: "\eebb"
}

.bxl-shopify:before {
	content: "\eebc"
}

.bxl-skype:before {
	content: "\eebd"
}

.bxl-slack:before {
	content: "\eebe"
}

.bxl-slack-old:before {
	content: "\eebf"
}

.bxl-snapchat:before {
	content: "\eec0"
}

.bxl-soundcloud:before {
	content: "\eec1"
}

.bxl-spotify:before {
	content: "\eec2"
}

.bxl-spring-boot:before {
	content: "\eec3"
}

.bxl-squarespace:before {
	content: "\eec4"
}

.bxl-stack-overflow:before {
	content: "\eec5"
}

.bxl-stripe:before {
	content: "\eec6"
}

.bxl-telegram:before {
	content: "\eec7"
}

.bxl-trello:before {
	content: "\eec8"
}

.bxl-tumblr:before {
	content: "\eec9"
}

.bxl-tux:before {
	content: "\eeca"
}

.bxl-twitch:before {
	content: "\eecb"
}

.bxl-twitter:before {
	content: "\eecc"
}

.bxl-unsplash:before {
	content: "\eecd"
}

.bxl-vimeo:before {
	content: "\eece"
}

.bxl-visa:before {
	content: "\eecf"
}

.bxl-vk:before {
	content: "\eed0"
}

.bxl-vuejs:before {
	content: "\eed1"
}

.bxl-whatsapp:before {
	content: "\eed2"
}

.bxl-whatsapp-square:before {
	content: "\eed3"
}

.bxl-wikipedia:before {
	content: "\eed4"
}

.bxl-windows:before {
	content: "\eed5"
}

.bxl-wix:before {
	content: "\eed6"
}

.bxl-wordpress:before {
	content: "\eed7"
}

.bxl-yahoo:before {
	content: "\eed8"
}

.bxl-yelp:before {
	content: "\eed9"
}

.bxl-youtube:before {
	content: "\eeda"
}

.bxl-zoom:before {
	content: "\eedb"
}

@font-face {
	font-family: LineIcons;
	src: url(../fonts/LineIcons.eot);
	src: url(../fonts/LineIcons.eot) format('embedded-opentype'), url(../fonts/LineIcons.woff2) format('woff2'), url(../fonts/LineIcons.woff) format('woff'), url(../fonts/LineIcons.ttf) format('truetype'), url(../fonts/LineIcons.svg) format('svg');
	font-weight: 400;
	font-style: normal
}

.lni {
	display: inline-block;
	font: normal normal normal 32px/1'LineIcons';
	font-size: inherit;
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.lni-500px::before {
	content: "\ea02"
}

.lni-add-files::before {
	content: "\ea03"
}

.lni-alarm-clock::before {
	content: "\ea04"
}

.lni-alarm::before {
	content: "\ea05"
}

.lni-airbnb::before {
	content: "\ea06"
}

.lni-adobe::before {
	content: "\ea07"
}

.lni-amazon-pay::before {
	content: "\ea08"
}

.lni-amazon::before {
	content: "\ea09"
}

.lni-amex::before {
	content: "\ea0a"
}

.lni-anchor::before {
	content: "\ea0b"
}

.lni-amazon-original::before {
	content: "\ea0c"
}

.lni-android-original::before {
	content: "\ea0d"
}

.lni-android::before {
	content: "\ea0e"
}

.lni-angellist::before {
	content: "\ea0f"
}

.lni-angle-double-down::before {
	content: "\ea10"
}

.lni-angle-double-left::before {
	content: "\ea11"
}

.lni-angle-double-right::before {
	content: "\ea12"
}

.lni-angle-double-up::before {
	content: "\ea13"
}

.lni-angular::before {
	content: "\ea14"
}

.lni-apartment::before {
	content: "\ea15"
}

.lni-app-store::before {
	content: "\ea16"
}

.lni-apple-pay::before {
	content: "\ea17"
}

.lni-apple::before {
	content: "\ea18"
}

.lni-archive::before {
	content: "\ea19"
}

.lni-arrow-down-circle::before {
	content: "\ea1a"
}

.lni-arrow-left-circle::before {
	content: "\ea1b"
}

.lni-arrow-left::before {
	content: "\ea1c"
}

.lni-arrow-right-circle::before {
	content: "\ea1d"
}

.lni-arrow-right::before {
	content: "\ea1e"
}

.lni-arrow-top-left::before {
	content: "\ea1f"
}

.lni-arrow-top-right::before {
	content: "\ea20"
}

.lni-arrow-up-circle::before {
	content: "\ea21"
}

.lni-arrow-up::before {
	content: "\ea22"
}

.lni-arrows-horizontal::before {
	content: "\ea23"
}

.lni-arrows-vertical::before {
	content: "\ea24"
}

.lni-atlassian::before {
	content: "\ea25"
}

.lni-aws::before {
	content: "\ea26"
}

.lni-arrow-down::before {
	content: "\ea27"
}

.lni-ambulance::before {
	content: "\ea28"
}

.lni-agenda::before {
	content: "\ea29"
}

.lni-backward::before {
	content: "\ea2a"
}

.lni-baloon::before {
	content: "\ea2b"
}

.lni-ban::before {
	content: "\ea2c"
}

.lni-bar-chart::before {
	content: "\ea2d"
}

.lni-behance-original::before {
	content: "\ea2e"
}

.lni-bitbucket::before {
	content: "\ea2f"
}

.lni-bitcoin::before {
	content: "\ea30"
}

.lni-blackboard::before {
	content: "\ea31"
}

.lni-blogger::before {
	content: "\ea32"
}

.lni-bluetooth::before {
	content: "\ea33"
}

.lni-bold::before {
	content: "\ea34"
}

.lni-bolt-alt::before {
	content: "\ea35"
}

.lni-bolt::before {
	content: "\ea36"
}

.lni-book::before {
	content: "\ea37"
}

.lni-bookmark-alt::before {
	content: "\ea38"
}

.lni-bookmark::before {
	content: "\ea39"
}

.lni-bootstrap::before {
	content: "\ea3a"
}

.lni-bricks::before {
	content: "\ea3b"
}

.lni-bridge::before {
	content: "\ea3c"
}

.lni-briefcase::before {
	content: "\ea3d"
}

.lni-brush-alt::before {
	content: "\ea3e"
}

.lni-brush::before {
	content: "\ea3f"
}

.lni-bubble::before {
	content: "\ea40"
}

.lni-bug::before {
	content: "\ea41"
}

.lni-bulb::before {
	content: "\ea42"
}

.lni-bullhorn::before {
	content: "\ea43"
}

.lni-burger::before {
	content: "\ea44"
}

.lni-bus::before {
	content: "\ea45"
}

.lni-cake::before {
	content: "\ea46"
}

.lni-calculator::before {
	content: "\ea47"
}

.lni-calendar::before {
	content: "\ea48"
}

.lni-camera::before {
	content: "\ea49"
}

.lni-candy-cane::before {
	content: "\ea4a"
}

.lni-candy::before {
	content: "\ea4b"
}

.lni-capsule::before {
	content: "\ea4c"
}

.lni-car-alt::before {
	content: "\ea4d"
}

.lni-car::before {
	content: "\ea4e"
}

.lni-caravan::before {
	content: "\ea4f"
}

.lni-cart-full::before {
	content: "\ea50"
}

.lni-cart::before {
	content: "\ea51"
}

.lni-certificate::before {
	content: "\ea52"
}

.lni-checkbox::before {
	content: "\ea53"
}

.lni-checkmark-circle::before {
	content: "\ea54"
}

.lni-checkmark::before {
	content: "\ea55"
}

.lni-chef-hat::before {
	content: "\ea56"
}

.lni-chevron-down-circle::before {
	content: "\ea57"
}

.lni-chevron-down::before {
	content: "\ea58"
}

.lni-chevron-left-circle::before {
	content: "\ea59"
}

.lni-chevron-left::before {
	content: "\ea5a"
}

.lni-chevron-right-circle::before {
	content: "\ea5b"
}

.lni-chevron-right::before {
	content: "\ea5c"
}

.lni-chevron-up-circle::before {
	content: "\ea5d"
}

.lni-chevron-up::before {
	content: "\ea5e"
}

.lni-chrome::before {
	content: "\ea5f"
}

.lni-circle-minus::before {
	content: "\ea60"
}

.lni-circle-plus::before {
	content: "\ea61"
}

.lni-clipboard::before {
	content: "\ea62"
}

.lni-close::before {
	content: "\ea63"
}

.lni-cloud-check::before {
	content: "\ea64"
}

.lni-cloud-download::before {
	content: "\ea65"
}

.lni-cloud-network::before {
	content: "\ea66"
}

.lni-cloud-sync::before {
	content: "\ea67"
}

.lni-cloud-upload::before {
	content: "\ea68"
}

.lni-cloud::before {
	content: "\ea69"
}

.lni-cloudy-sun::before {
	content: "\ea6a"
}

.lni-code-alt::before {
	content: "\ea6b"
}

.lni-code::before {
	content: "\ea6c"
}

.lni-codepen::before {
	content: "\ea6d"
}

.lni-coffee-cup::before {
	content: "\ea6e"
}

.lni-cog::before {
	content: "\ea6f"
}

.lni-cogs::before {
	content: "\ea70"
}

.lni-coin::before {
	content: "\ea71"
}

.lni-comments-alt::before {
	content: "\ea72"
}

.lni-comments-reply::before {
	content: "\ea73"
}

.lni-comments::before {
	content: "\ea74"
}

.lni-compass::before {
	content: "\ea75"
}

.lni-construction-hammer::before {
	content: "\ea76"
}

.lni-construction::before {
	content: "\ea77"
}

.lni-consulting::before {
	content: "\ea78"
}

.lni-control-panel::before {
	content: "\ea79"
}

.lni-cpanel::before {
	content: "\ea7a"
}

.lni-creative-commons::before {
	content: "\ea7b"
}

.lni-credit-cards::before {
	content: "\ea7c"
}

.lni-crop::before {
	content: "\ea7d"
}

.lni-cross-circle::before {
	content: "\ea7e"
}

.lni-crown::before {
	content: "\ea7f"
}

.lni-css3::before {
	content: "\ea80"
}

.lni-cup::before {
	content: "\ea81"
}

.lni-customer::before {
	content: "\ea82"
}

.lni-cut::before {
	content: "\ea83"
}

.lni-dashboard::before {
	content: "\ea84"
}

.lni-database::before {
	content: "\ea85"
}

.lni-delivery::before {
	content: "\ea86"
}

.lni-dev::before {
	content: "\ea87"
}

.lni-diamond-alt::before {
	content: "\ea88"
}

.lni-diamond::before {
	content: "\ea89"
}

.lni-diners-club::before {
	content: "\ea8a"
}

.lni-dinner::before {
	content: "\ea8b"
}

.lni-direction-alt::before {
	content: "\ea8c"
}

.lni-direction-ltr::before {
	content: "\ea8d"
}

.lni-direction-rtl::before {
	content: "\ea8e"
}

.lni-direction::before {
	content: "\ea8f"
}

.lni-discord::before {
	content: "\ea90"
}

.lni-discover::before {
	content: "\ea91"
}

.lni-display-alt::before {
	content: "\ea92"
}

.lni-display::before {
	content: "\ea93"
}

.lni-docker::before {
	content: "\ea94"
}

.lni-dollar::before {
	content: "\ea95"
}

.lni-domain::before {
	content: "\ea96"
}

.lni-download::before {
	content: "\ea97"
}

.lni-dribbble::before {
	content: "\ea98"
}

.lni-drop::before {
	content: "\ea99"
}

.lni-dropbox-original::before {
	content: "\ea9a"
}

.lni-dropbox::before {
	content: "\ea9b"
}

.lni-drupal-original::before {
	content: "\ea9c"
}

.lni-drupal::before {
	content: "\ea9d"
}

.lni-dumbbell::before {
	content: "\ea9e"
}

.lni-edge::before {
	content: "\ea9f"
}

.lni-emoji-cool::before {
	content: "\eaa0"
}

.lni-emoji-friendly::before {
	content: "\eaa1"
}

.lni-emoji-happy::before {
	content: "\eaa2"
}

.lni-emoji-sad::before {
	content: "\eaa3"
}

.lni-emoji-smile::before {
	content: "\eaa4"
}

.lni-emoji-speechless::before {
	content: "\eaa5"
}

.lni-emoji-suspect::before {
	content: "\eaa6"
}

.lni-emoji-tounge::before {
	content: "\eaa7"
}

.lni-empty-file::before {
	content: "\eaa8"
}

.lni-enter::before {
	content: "\eaa9"
}

.lni-envato::before {
	content: "\eaaa"
}

.lni-envelope::before {
	content: "\eaab"
}

.lni-eraser::before {
	content: "\eaac"
}

.lni-euro::before {
	content: "\eaad"
}

.lni-exit-down::before {
	content: "\eaae"
}

.lni-exit-up::before {
	content: "\eaaf"
}

.lni-exit::before {
	content: "\eab0"
}

.lni-eye::before {
	content: "\eab1"
}

.lni-facebook-filled::before {
	content: "\eab2"
}

.lni-facebook-messenger::before {
	content: "\eab3"
}

.lni-facebook-original::before {
	content: "\eab4"
}

.lni-facebook-oval::before {
	content: "\eab5"
}

.lni-facebook::before {
	content: "\eab6"
}

.lni-figma::before {
	content: "\eab7"
}

.lni-files::before {
	content: "\eab8"
}

.lni-firefox-original::before {
	content: "\eab9"
}

.lni-firefox::before {
	content: "\eaba"
}

.lni-fireworks::before {
	content: "\eabb"
}

.lni-first-aid::before {
	content: "\eabc"
}

.lni-flag-alt::before {
	content: "\eabd"
}

.lni-flag::before {
	content: "\eabe"
}

.lni-flags::before {
	content: "\eabf"
}

.lni-flickr::before {
	content: "\eac0"
}

.lni-basketball::before {
	content: "\eac1"
}

.lni-behance::before {
	content: "\eac2"
}

.lni-forward::before {
	content: "\eac3"
}

.lni-frame-expand::before {
	content: "\eac4"
}

.lni-flower::before {
	content: "\eac5"
}

.lni-full-screen::before {
	content: "\eac6"
}

.lni-funnel::before {
	content: "\eac7"
}

.lni-gallery::before {
	content: "\eac8"
}

.lni-game::before {
	content: "\eac9"
}

.lni-gift::before {
	content: "\eaca"
}

.lni-git::before {
	content: "\eacb"
}

.lni-github-original::before {
	content: "\eacc"
}

.lni-github::before {
	content: "\eacd"
}

.lni-goodreads::before {
	content: "\eace"
}

.lni-google-drive::before {
	content: "\eacf"
}

.lni-google-pay::before {
	content: "\ead0"
}

.lni-fresh-juice::before {
	content: "\ead1"
}

.lni-folder::before {
	content: "\ead2"
}

.lni-bi-cycle::before {
	content: "\ead3"
}

.lni-graph::before {
	content: "\ead4"
}

.lni-grid-alt::before {
	content: "\ead5"
}

.lni-grid::before {
	content: "\ead6"
}

.lni-google-wallet::before {
	content: "\ead7"
}

.lni-grow::before {
	content: "\ead8"
}

.lni-hammer::before {
	content: "\ead9"
}

.lni-hand::before {
	content: "\eada"
}

.lni-handshake::before {
	content: "\eadb"
}

.lni-harddrive::before {
	content: "\eadc"
}

.lni-headphone-alt::before {
	content: "\eadd"
}

.lni-headphone::before {
	content: "\eade"
}

.lni-heart-filled::before {
	content: "\eadf"
}

.lni-heart-monitor::before {
	content: "\eae0"
}

.lni-heart::before {
	content: "\eae1"
}

.lni-helicopter::before {
	content: "\eae2"
}

.lni-helmet::before {
	content: "\eae3"
}

.lni-help::before {
	content: "\eae4"
}

.lni-highlight-alt::before {
	content: "\eae5"
}

.lni-highlight::before {
	content: "\eae6"
}

.lni-home::before {
	content: "\eae7"
}

.lni-hospital::before {
	content: "\eae8"
}

.lni-hourglass::before {
	content: "\eae9"
}

.lni-html5::before {
	content: "\eaea"
}

.lni-image::before {
	content: "\eaeb"
}

.lni-inbox::before {
	content: "\eaec"
}

.lni-indent-decrease::before {
	content: "\eaed"
}

.lni-indent-increase::before {
	content: "\eaee"
}

.lni-infinite::before {
	content: "\eaef"
}

.lni-information::before {
	content: "\eaf0"
}

.lni-instagram-filled::before {
	content: "\eaf1"
}

.lni-instagram-original::before {
	content: "\eaf2"
}

.lni-instagram::before {
	content: "\eaf3"
}

.lni-invention::before {
	content: "\eaf4"
}

.lni-graduation::before {
	content: "\eaf5"
}

.lni-invest-monitor::before {
	content: "\eaf6"
}

.lni-island::before {
	content: "\eaf7"
}

.lni-italic::before {
	content: "\eaf8"
}

.lni-java::before {
	content: "\eaf9"
}

.lni-javascript::before {
	content: "\eafa"
}

.lni-jcb::before {
	content: "\eafb"
}

.lni-joomla-original::before {
	content: "\eafc"
}

.lni-joomla::before {
	content: "\eafd"
}

.lni-jsfiddle::before {
	content: "\eafe"
}

.lni-juice::before {
	content: "\eaff"
}

.lni-key::before {
	content: "\eb00"
}

.lni-keyboard::before {
	content: "\eb01"
}

.lni-keyword-research::before {
	content: "\eb02"
}

.lni-hacker-news::before {
	content: "\eb03"
}

.lni-google::before {
	content: "\eb04"
}

.lni-laravel::before {
	content: "\eb05"
}

.lni-layers::before {
	content: "\eb06"
}

.lni-layout::before {
	content: "\eb07"
}

.lni-leaf::before {
	content: "\eb08"
}

.lni-library::before {
	content: "\eb09"
}

.lni-licencse::before {
	content: "\eb0a"
}

.lni-life-ring::before {
	content: "\eb0b"
}

.lni-line-dashed::before {
	content: "\eb0c"
}

.lni-line-dotted::before {
	content: "\eb0d"
}

.lni-line-double::before {
	content: "\eb0e"
}

.lni-line-spacing::before {
	content: "\eb0f"
}

.lni-line::before {
	content: "\eb10"
}

.lni-lineicons-alt::before {
	content: "\eb11"
}

.lni-lineicons::before {
	content: "\eb12"
}

.lni-link::before {
	content: "\eb13"
}

.lni-linkedin-original::before {
	content: "\eb14"
}

.lni-linkedin::before {
	content: "\eb15"
}

.lni-list::before {
	content: "\eb16"
}

.lni-lock-alt::before {
	content: "\eb17"
}

.lni-lock::before {
	content: "\eb18"
}

.lni-magnet::before {
	content: "\eb19"
}

.lni-magnifier::before {
	content: "\eb1a"
}

.lni-mailchimp::before {
	content: "\eb1b"
}

.lni-map-marker::before {
	content: "\eb1c"
}

.lni-map::before {
	content: "\eb1d"
}

.lni-mashroom::before {
	content: "\eb1e"
}

.lni-mastercard::before {
	content: "\eb1f"
}

.lni-medall-alt::before {
	content: "\eb20"
}

.lni-medall::before {
	content: "\eb21"
}

.lni-medium::before {
	content: "\eb22"
}

.lni-laptop::before {
	content: "\eb23"
}

.lni-investment::before {
	content: "\eb24"
}

.lni-laptop-phone::before {
	content: "\eb25"
}

.lni-megento::before {
	content: "\eb26"
}

.lni-mic::before {
	content: "\eb27"
}

.lni-microphone::before {
	content: "\eb28"
}

.lni-menu::before {
	content: "\eb29"
}

.lni-microscope::before {
	content: "\eb2a"
}

.lni-money-location::before {
	content: "\eb2b"
}

.lni-minus::before {
	content: "\eb2c"
}

.lni-mobile::before {
	content: "\eb2d"
}

.lni-more-alt::before {
	content: "\eb2e"
}

.lni-mouse::before {
	content: "\eb2f"
}

.lni-move::before {
	content: "\eb30"
}

.lni-music::before {
	content: "\eb31"
}

.lni-network::before {
	content: "\eb32"
}

.lni-night::before {
	content: "\eb33"
}

.lni-nodejs-alt::before {
	content: "\eb34"
}

.lni-nodejs::before {
	content: "\eb35"
}

.lni-notepad::before {
	content: "\eb36"
}

.lni-npm::before {
	content: "\eb37"
}

.lni-offer::before {
	content: "\eb38"
}

.lni-opera::before {
	content: "\eb39"
}

.lni-package::before {
	content: "\eb3a"
}

.lni-page-break::before {
	content: "\eb3b"
}

.lni-pagination::before {
	content: "\eb3c"
}

.lni-paint-bucket::before {
	content: "\eb3d"
}

.lni-paint-roller::before {
	content: "\eb3e"
}

.lni-pallet::before {
	content: "\eb3f"
}

.lni-paperclip::before {
	content: "\eb40"
}

.lni-more::before {
	content: "\eb41"
}

.lni-pause::before {
	content: "\eb42"
}

.lni-paypal-original::before {
	content: "\eb43"
}

.lni-microsoft::before {
	content: "\eb44"
}

.lni-money-protection::before {
	content: "\eb45"
}

.lni-pencil::before {
	content: "\eb46"
}

.lni-paypal::before {
	content: "\eb47"
}

.lni-pencil-alt::before {
	content: "\eb48"
}

.lni-patreon::before {
	content: "\eb49"
}

.lni-phone-set::before {
	content: "\eb4a"
}

.lni-phone::before {
	content: "\eb4b"
}

.lni-pin::before {
	content: "\eb4c"
}

.lni-pinterest::before {
	content: "\eb4d"
}

.lni-pie-chart::before {
	content: "\eb4e"
}

.lni-pilcrow::before {
	content: "\eb4f"
}

.lni-plane::before {
	content: "\eb50"
}

.lni-play::before {
	content: "\eb51"
}

.lni-plug::before {
	content: "\eb52"
}

.lni-plus::before {
	content: "\eb53"
}

.lni-pointer-down::before {
	content: "\eb54"
}

.lni-pointer-left::before {
	content: "\eb55"
}

.lni-pointer-right::before {
	content: "\eb56"
}

.lni-pointer-up::before {
	content: "\eb57"
}

.lni-play-store::before {
	content: "\eb58"
}

.lni-pizza::before {
	content: "\eb59"
}

.lni-postcard::before {
	content: "\eb5a"
}

.lni-pound::before {
	content: "\eb5b"
}

.lni-power-switch::before {
	content: "\eb5c"
}

.lni-printer::before {
	content: "\eb5d"
}

.lni-producthunt::before {
	content: "\eb5e"
}

.lni-protection::before {
	content: "\eb5f"
}

.lni-pulse::before {
	content: "\eb60"
}

.lni-pyramids::before {
	content: "\eb61"
}

.lni-python::before {
	content: "\eb62"
}

.lni-pointer::before {
	content: "\eb63"
}

.lni-popup::before {
	content: "\eb64"
}

.lni-quotation::before {
	content: "\eb65"
}

.lni-radio-button::before {
	content: "\eb66"
}

.lni-rain::before {
	content: "\eb67"
}

.lni-quora::before {
	content: "\eb68"
}

.lni-react::before {
	content: "\eb69"
}

.lni-question-circle::before {
	content: "\eb6a"
}

.lni-php::before {
	content: "\eb6b"
}

.lni-reddit::before {
	content: "\eb6c"
}

.lni-reload::before {
	content: "\eb6d"
}

.lni-restaurant::before {
	content: "\eb6e"
}

.lni-road::before {
	content: "\eb6f"
}

.lni-rocket::before {
	content: "\eb70"
}

.lni-rss-feed::before {
	content: "\eb71"
}

.lni-ruler-alt::before {
	content: "\eb72"
}

.lni-ruler-pencil::before {
	content: "\eb73"
}

.lni-ruler::before {
	content: "\eb74"
}

.lni-rupee::before {
	content: "\eb75"
}

.lni-save::before {
	content: "\eb76"
}

.lni-school-bench-alt::before {
	content: "\eb77"
}

.lni-school-bench::before {
	content: "\eb78"
}

.lni-scooter::before {
	content: "\eb79"
}

.lni-scroll-down::before {
	content: "\eb7a"
}

.lni-search-alt::before {
	content: "\eb7b"
}

.lni-search::before {
	content: "\eb7c"
}

.lni-select::before {
	content: "\eb7d"
}

.lni-seo::before {
	content: "\eb7e"
}

.lni-service::before {
	content: "\eb7f"
}

.lni-share-alt::before {
	content: "\eb80"
}

.lni-share::before {
	content: "\eb81"
}

.lni-shield::before {
	content: "\eb82"
}

.lni-shift-left::before {
	content: "\eb83"
}

.lni-shift-right::before {
	content: "\eb84"
}

.lni-ship::before {
	content: "\eb85"
}

.lni-shopify::before {
	content: "\eb86"
}

.lni-shopping-basket::before {
	content: "\eb87"
}

.lni-shortcode::before {
	content: "\eb88"
}

.lni-shovel::before {
	content: "\eb89"
}

.lni-shuffle::before {
	content: "\eb8a"
}

.lni-signal::before {
	content: "\eb8b"
}

.lni-sketch::before {
	content: "\eb8c"
}

.lni-skipping-rope::before {
	content: "\eb8d"
}

.lni-skype::before {
	content: "\eb8e"
}

.lni-slack::before {
	content: "\eb8f"
}

.lni-slice::before {
	content: "\eb90"
}

.lni-slideshare::before {
	content: "\eb91"
}

.lni-slim::before {
	content: "\eb92"
}

.lni-reply::before {
	content: "\eb93"
}

.lni-sort-alpha-asc::before {
	content: "\eb94"
}

.lni-remove-file::before {
	content: "\eb95"
}

.lni-sort-amount-dsc::before {
	content: "\eb96"
}

.lni-sort-amount-asc::before {
	content: "\eb97"
}

.lni-soundcloud::before {
	content: "\eb98"
}

.lni-souncloud-original::before {
	content: "\eb99"
}

.lni-spiner-solid::before {
	content: "\eb9a"
}

.lni-revenue::before {
	content: "\eb9b"
}

.lni-spinner::before {
	content: "\eb9c"
}

.lni-spellcheck::before {
	content: "\eb9d"
}

.lni-spotify::before {
	content: "\eb9e"
}

.lni-spray::before {
	content: "\eb9f"
}

.lni-sprout::before {
	content: "\eba0"
}

.lni-snapchat::before {
	content: "\eba1"
}

.lni-stamp::before {
	content: "\eba2"
}

.lni-star-empty::before {
	content: "\eba3"
}

.lni-star-filled::before {
	content: "\eba4"
}

.lni-star-half::before {
	content: "\eba5"
}

.lni-star::before {
	content: "\eba6"
}

.lni-stats-down::before {
	content: "\eba7"
}

.lni-spinner-arrow::before {
	content: "\eba8"
}

.lni-steam::before {
	content: "\eba9"
}

.lni-stackoverflow::before {
	content: "\ebaa"
}

.lni-stop::before {
	content: "\ebab"
}

.lni-strikethrough::before {
	content: "\ebac"
}

.lni-sthethoscope::before {
	content: "\ebad"
}

.lni-stumbleupon::before {
	content: "\ebae"
}

.lni-sun::before {
	content: "\ebaf"
}

.lni-support::before {
	content: "\ebb0"
}

.lni-surf-board::before {
	content: "\ebb1"
}

.lni-swift::before {
	content: "\ebb2"
}

.lni-syringe::before {
	content: "\ebb3"
}

.lni-tab::before {
	content: "\ebb4"
}

.lni-tag::before {
	content: "\ebb5"
}

.lni-target-customer::before {
	content: "\ebb6"
}

.lni-target-revenue::before {
	content: "\ebb7"
}

.lni-target::before {
	content: "\ebb8"
}

.lni-taxi::before {
	content: "\ebb9"
}

.lni-stats-up::before {
	content: "\ebba"
}

.lni-telegram-original::before {
	content: "\ebbb"
}

.lni-telegram::before {
	content: "\ebbc"
}

.lni-text-align-center::before {
	content: "\ebbd"
}

.lni-text-align-justify::before {
	content: "\ebbe"
}

.lni-text-align-left::before {
	content: "\ebbf"
}

.lni-text-format-remove::before {
	content: "\ebc0"
}

.lni-text-align-right::before {
	content: "\ebc1"
}

.lni-text-format::before {
	content: "\ebc2"
}

.lni-thought::before {
	content: "\ebc3"
}

.lni-thumbs-down::before {
	content: "\ebc4"
}

.lni-thumbs-up::before {
	content: "\ebc5"
}

.lni-thunder-alt::before {
	content: "\ebc6"
}

.lni-thunder::before {
	content: "\ebc7"
}

.lni-ticket-alt::before {
	content: "\ebc8"
}

.lni-ticket::before {
	content: "\ebc9"
}

.lni-timer::before {
	content: "\ebca"
}

.lni-train-alt::before {
	content: "\ebcb"
}

.lni-train::before {
	content: "\ebcc"
}

.lni-trash::before {
	content: "\ebcd"
}

.lni-travel::before {
	content: "\ebce"
}

.lni-tree::before {
	content: "\ebcf"
}

.lni-trees::before {
	content: "\ebd0"
}

.lni-trello::before {
	content: "\ebd1"
}

.lni-trowel::before {
	content: "\ebd2"
}

.lni-tshirt::before {
	content: "\ebd3"
}

.lni-tumblr::before {
	content: "\ebd4"
}

.lni-twitch::before {
	content: "\ebd5"
}

.lni-twitter-filled::before {
	content: "\ebd6"
}

.lni-twitter-original::before {
	content: "\ebd7"
}

.lni-twitter::before {
	content: "\ebd8"
}

.lni-ubuntu::before {
	content: "\ebd9"
}

.lni-underline::before {
	content: "\ebda"
}

.lni-unlink::before {
	content: "\ebdb"
}

.lni-unlock::before {
	content: "\ebdc"
}

.lni-upload::before {
	content: "\ebdd"
}

.lni-user::before {
	content: "\ebde"
}

.lni-users::before {
	content: "\ebdf"
}

.lni-ux::before {
	content: "\ebe0"
}

.lni-vector::before {
	content: "\ebe1"
}

.lni-video::before {
	content: "\ebe2"
}

.lni-vimeo::before {
	content: "\ebe3"
}

.lni-visa::before {
	content: "\ebe4"
}

.lni-vk::before {
	content: "\ebe5"
}

.lni-volume-high::before {
	content: "\ebe6"
}

.lni-volume-low::before {
	content: "\ebe7"
}

.lni-volume-medium::before {
	content: "\ebe8"
}

.lni-volume-mute::before {
	content: "\ebe9"
}

.lni-volume::before {
	content: "\ebea"
}

.lni-wallet::before {
	content: "\ebeb"
}

.lni-warning::before {
	content: "\ebec"
}

.lni-website-alt::before {
	content: "\ebed"
}

.lni-website::before {
	content: "\ebee"
}

.lni-wechat::before {
	content: "\ebef"
}

.lni-weight::before {
	content: "\ebf0"
}

.lni-whatsapp::before {
	content: "\ebf1"
}

.lni-wheelbarrow::before {
	content: "\ebf2"
}

.lni-wheelchair::before {
	content: "\ebf3"
}

.lni-windows::before {
	content: "\ebf4"
}

.lni-wordpress-filled::before {
	content: "\ebf5"
}

.lni-wordpress::before {
	content: "\ebf6"
}

.lni-world-alt::before {
	content: "\ebf7"
}

.lni-world::before {
	content: "\ebf8"
}

.lni-write::before {
	content: "\ebf9"
}

.lni-yahoo::before {
	content: "\ebfa"
}

.lni-ycombinator::before {
	content: "\ebfb"
}

.lni-yen::before {
	content: "\ebfc"
}

.lni-youtube::before {
	content: "\ebfd"
}

.lni-zip::before {
	content: "\ebfe"
}

.lni-zoom-in::before {
	content: "\ebff"
}

.lni-zoom-out::before {
	content: "\ec00"
}

.lni-teabag::before {
	content: "\ec01"
}

.lni-stripe::before {
	content: "\ec02"
}

.lni-spotify-original::before {
	content: "\ec03"
}

.flag-icon-background {
	background-size: contain;
	background-position: 50%;
	background-repeat: no-repeat
}

.flag-icon {
	background-size: contain;
	background-position: 50%;
	background-repeat: no-repeat;
	position: relative;
	display: inline-block;
	width: 1.33333333em;
	line-height: 1em
}

.flag-icon:before {
	content: "\00a0"
}

.flag-icon.flag-icon-squared {
	width: 1em
}

.flag-icon-ad {
	background-image: url(../flags/4x3/ad.svg)
}

.flag-icon-ad.flag-icon-squared {
	background-image: url(../flags/1x1/ad.svg)
}

.flag-icon-ae {
	background-image: url(../flags/4x3/ae.svg)
}

.flag-icon-ae.flag-icon-squared {
	background-image: url(../flags/1x1/ae.svg)
}

.flag-icon-af {
	background-image: url(../flags/4x3/af.svg)
}

.flag-icon-af.flag-icon-squared {
	background-image: url(../flags/1x1/af.svg)
}

.flag-icon-ag {
	background-image: url(../flags/4x3/ag.svg)
}

.flag-icon-ag.flag-icon-squared {
	background-image: url(../flags/1x1/ag.svg)
}

.flag-icon-ai {
	background-image: url(../flags/4x3/ai.svg)
}

.flag-icon-ai.flag-icon-squared {
	background-image: url(../flags/1x1/ai.svg)
}

.flag-icon-al {
	background-image: url(../flags/4x3/al.svg)
}

.flag-icon-al.flag-icon-squared {
	background-image: url(../flags/1x1/al.svg)
}

.flag-icon-am {
	background-image: url(../flags/4x3/am.svg)
}

.flag-icon-am.flag-icon-squared {
	background-image: url(../flags/1x1/am.svg)
}

.flag-icon-ao {
	background-image: url(../flags/4x3/ao.svg)
}

.flag-icon-ao.flag-icon-squared {
	background-image: url(../flags/1x1/ao.svg)
}

.flag-icon-aq {
	background-image: url(../flags/4x3/aq.svg)
}

.flag-icon-aq.flag-icon-squared {
	background-image: url(../flags/1x1/aq.svg)
}

.flag-icon-ar {
	background-image: url(../flags/4x3/ar.svg)
}

.flag-icon-ar.flag-icon-squared {
	background-image: url(../flags/1x1/ar.svg)
}

.flag-icon-as {
	background-image: url(../flags/4x3/as.svg)
}

.flag-icon-as.flag-icon-squared {
	background-image: url(../flags/1x1/as.svg)
}

.flag-icon-at {
	background-image: url(../flags/4x3/at.svg)
}

.flag-icon-at.flag-icon-squared {
	background-image: url(../flags/1x1/at.svg)
}

.flag-icon-au {
	background-image: url(../flags/4x3/au.svg)
}

.flag-icon-au.flag-icon-squared {
	background-image: url(../flags/1x1/au.svg)
}

.flag-icon-aw {
	background-image: url(../flags/4x3/aw.svg)
}

.flag-icon-aw.flag-icon-squared {
	background-image: url(../flags/1x1/aw.svg)
}

.flag-icon-ax {
	background-image: url(../flags/4x3/ax.svg)
}

.flag-icon-ax.flag-icon-squared {
	background-image: url(../flags/1x1/ax.svg)
}

.flag-icon-az {
	background-image: url(../flags/4x3/az.svg)
}

.flag-icon-az.flag-icon-squared {
	background-image: url(../flags/1x1/az.svg)
}

.flag-icon-ba {
	background-image: url(../flags/4x3/ba.svg)
}

.flag-icon-ba.flag-icon-squared {
	background-image: url(../flags/1x1/ba.svg)
}

.flag-icon-bb {
	background-image: url(../flags/4x3/bb.svg)
}

.flag-icon-bb.flag-icon-squared {
	background-image: url(../flags/1x1/bb.svg)
}

.flag-icon-bd {
	background-image: url(../flags/4x3/bd.svg)
}

.flag-icon-bd.flag-icon-squared {
	background-image: url(../flags/1x1/bd.svg)
}

.flag-icon-be {
	background-image: url(../flags/4x3/be.svg)
}

.flag-icon-be.flag-icon-squared {
	background-image: url(../flags/1x1/be.svg)
}

.flag-icon-bf {
	background-image: url(../flags/4x3/bf.svg)
}

.flag-icon-bf.flag-icon-squared {
	background-image: url(../flags/1x1/bf.svg)
}

.flag-icon-bg {
	background-image: url(../flags/4x3/bg.svg)
}

.flag-icon-bg.flag-icon-squared {
	background-image: url(../flags/1x1/bg.svg)
}

.flag-icon-bh {
	background-image: url(../flags/4x3/bh.svg)
}

.flag-icon-bh.flag-icon-squared {
	background-image: url(../flags/1x1/bh.svg)
}

.flag-icon-bi {
	background-image: url(../flags/4x3/bi.svg)
}

.flag-icon-bi.flag-icon-squared {
	background-image: url(../flags/1x1/bi.svg)
}

.flag-icon-bj {
	background-image: url(../flags/4x3/bj.svg)
}

.flag-icon-bj.flag-icon-squared {
	background-image: url(../flags/1x1/bj.svg)
}

.flag-icon-bl {
	background-image: url(../flags/4x3/bl.svg)
}

.flag-icon-bl.flag-icon-squared {
	background-image: url(../flags/1x1/bl.svg)
}

.flag-icon-bm {
	background-image: url(../flags/4x3/bm.svg)
}

.flag-icon-bm.flag-icon-squared {
	background-image: url(../flags/1x1/bm.svg)
}

.flag-icon-bn {
	background-image: url(../flags/4x3/bn.svg)
}

.flag-icon-bn.flag-icon-squared {
	background-image: url(../flags/1x1/bn.svg)
}

.flag-icon-bo {
	background-image: url(../flags/4x3/bo.svg)
}

.flag-icon-bo.flag-icon-squared {
	background-image: url(../flags/1x1/bo.svg)
}

.flag-icon-bq {
	background-image: url(../flags/4x3/bq.svg)
}

.flag-icon-bq.flag-icon-squared {
	background-image: url(../flags/1x1/bq.svg)
}

.flag-icon-br {
	background-image: url(../flags/4x3/br.svg)
}

.flag-icon-br.flag-icon-squared {
	background-image: url(../flags/1x1/br.svg)
}

.flag-icon-bs {
	background-image: url(../flags/4x3/bs.svg)
}

.flag-icon-bs.flag-icon-squared {
	background-image: url(../flags/1x1/bs.svg)
}

.flag-icon-bt {
	background-image: url(../flags/4x3/bt.svg)
}

.flag-icon-bt.flag-icon-squared {
	background-image: url(../flags/1x1/bt.svg)
}

.flag-icon-bv {
	background-image: url(../flags/4x3/bv.svg)
}

.flag-icon-bv.flag-icon-squared {
	background-image: url(../flags/1x1/bv.svg)
}

.flag-icon-bw {
	background-image: url(../flags/4x3/bw.svg)
}

.flag-icon-bw.flag-icon-squared {
	background-image: url(../flags/1x1/bw.svg)
}

.flag-icon-by {
	background-image: url(../flags/4x3/by.svg)
}

.flag-icon-by.flag-icon-squared {
	background-image: url(../flags/1x1/by.svg)
}

.flag-icon-bz {
	background-image: url(../flags/4x3/bz.svg)
}

.flag-icon-bz.flag-icon-squared {
	background-image: url(../flags/1x1/bz.svg)
}

.flag-icon-ca {
	background-image: url(../flags/4x3/ca.svg)
}

.flag-icon-ca.flag-icon-squared {
	background-image: url(../flags/1x1/ca.svg)
}

.flag-icon-cc {
	background-image: url(../flags/4x3/cc.svg)
}

.flag-icon-cc.flag-icon-squared {
	background-image: url(../flags/1x1/cc.svg)
}

.flag-icon-cd {
	background-image: url(../flags/4x3/cd.svg)
}

.flag-icon-cd.flag-icon-squared {
	background-image: url(../flags/1x1/cd.svg)
}

.flag-icon-cf {
	background-image: url(../flags/4x3/cf.svg)
}

.flag-icon-cf.flag-icon-squared {
	background-image: url(../flags/1x1/cf.svg)
}

.flag-icon-cg {
	background-image: url(../flags/4x3/cg.svg)
}

.flag-icon-cg.flag-icon-squared {
	background-image: url(../flags/1x1/cg.svg)
}

.flag-icon-ch {
	background-image: url(../flags/4x3/ch.svg)
}

.flag-icon-ch.flag-icon-squared {
	background-image: url(../flags/1x1/ch.svg)
}

.flag-icon-ci {
	background-image: url(../flags/4x3/ci.svg)
}

.flag-icon-ci.flag-icon-squared {
	background-image: url(../flags/1x1/ci.svg)
}

.flag-icon-ck {
	background-image: url(../flags/4x3/ck.svg)
}

.flag-icon-ck.flag-icon-squared {
	background-image: url(../flags/1x1/ck.svg)
}

.flag-icon-cl {
	background-image: url(../flags/4x3/cl.svg)
}

.flag-icon-cl.flag-icon-squared {
	background-image: url(../flags/1x1/cl.svg)
}

.flag-icon-cm {
	background-image: url(../flags/4x3/cm.svg)
}

.flag-icon-cm.flag-icon-squared {
	background-image: url(../flags/1x1/cm.svg)
}

.flag-icon-cn {
	background-image: url(../flags/4x3/cn.svg)
}

.flag-icon-cn.flag-icon-squared {
	background-image: url(../flags/1x1/cn.svg)
}

.flag-icon-co {
	background-image: url(../flags/4x3/co.svg)
}

.flag-icon-co.flag-icon-squared {
	background-image: url(../flags/1x1/co.svg)
}

.flag-icon-cr {
	background-image: url(../flags/4x3/cr.svg)
}

.flag-icon-cr.flag-icon-squared {
	background-image: url(../flags/1x1/cr.svg)
}

.flag-icon-cu {
	background-image: url(../flags/4x3/cu.svg)
}

.flag-icon-cu.flag-icon-squared {
	background-image: url(../flags/1x1/cu.svg)
}

.flag-icon-cv {
	background-image: url(../flags/4x3/cv.svg)
}

.flag-icon-cv.flag-icon-squared {
	background-image: url(../flags/1x1/cv.svg)
}

.flag-icon-cw {
	background-image: url(../flags/4x3/cw.svg)
}

.flag-icon-cw.flag-icon-squared {
	background-image: url(../flags/1x1/cw.svg)
}

.flag-icon-cx {
	background-image: url(../flags/4x3/cx.svg)
}

.flag-icon-cx.flag-icon-squared {
	background-image: url(../flags/1x1/cx.svg)
}

.flag-icon-cy {
	background-image: url(../flags/4x3/cy.svg)
}

.flag-icon-cy.flag-icon-squared {
	background-image: url(../flags/1x1/cy.svg)
}

.flag-icon-cz {
	background-image: url(../flags/4x3/cz.svg)
}

.flag-icon-cz.flag-icon-squared {
	background-image: url(../flags/1x1/cz.svg)
}

.flag-icon-de {
	background-image: url(../flags/4x3/de.svg)
}

.flag-icon-de.flag-icon-squared {
	background-image: url(../flags/1x1/de.svg)
}

.flag-icon-dj {
	background-image: url(../flags/4x3/dj.svg)
}

.flag-icon-dj.flag-icon-squared {
	background-image: url(../flags/1x1/dj.svg)
}

.flag-icon-dk {
	background-image: url(../flags/4x3/dk.svg)
}

.flag-icon-dk.flag-icon-squared {
	background-image: url(../flags/1x1/dk.svg)
}

.flag-icon-dm {
	background-image: url(../flags/4x3/dm.svg)
}

.flag-icon-dm.flag-icon-squared {
	background-image: url(../flags/1x1/dm.svg)
}

.flag-icon-do {
	background-image: url(../flags/4x3/do.svg)
}

.flag-icon-do.flag-icon-squared {
	background-image: url(../flags/1x1/do.svg)
}

.flag-icon-dz {
	background-image: url(../flags/4x3/dz.svg)
}

.flag-icon-dz.flag-icon-squared {
	background-image: url(../flags/1x1/dz.svg)
}

.flag-icon-ec {
	background-image: url(../flags/4x3/ec.svg)
}

.flag-icon-ec.flag-icon-squared {
	background-image: url(../flags/1x1/ec.svg)
}

.flag-icon-ee {
	background-image: url(../flags/4x3/ee.svg)
}

.flag-icon-ee.flag-icon-squared {
	background-image: url(../flags/1x1/ee.svg)
}

.flag-icon-eg {
	background-image: url(../flags/4x3/eg.svg)
}

.flag-icon-eg.flag-icon-squared {
	background-image: url(../flags/1x1/eg.svg)
}

.flag-icon-eh {
	background-image: url(../flags/4x3/eh.svg)
}

.flag-icon-eh.flag-icon-squared {
	background-image: url(../flags/1x1/eh.svg)
}

.flag-icon-er {
	background-image: url(../flags/4x3/er.svg)
}

.flag-icon-er.flag-icon-squared {
	background-image: url(../flags/1x1/er.svg)
}

.flag-icon-es {
	background-image: url(../flags/4x3/es.svg)
}

.flag-icon-es.flag-icon-squared {
	background-image: url(../flags/1x1/es.svg)
}

.flag-icon-et {
	background-image: url(../flags/4x3/et.svg)
}

.flag-icon-et.flag-icon-squared {
	background-image: url(../flags/1x1/et.svg)
}

.flag-icon-fi {
	background-image: url(../flags/4x3/fi.svg)
}

.flag-icon-fi.flag-icon-squared {
	background-image: url(../flags/1x1/fi.svg)
}

.flag-icon-fj {
	background-image: url(../flags/4x3/fj.svg)
}

.flag-icon-fj.flag-icon-squared {
	background-image: url(../flags/1x1/fj.svg)
}

.flag-icon-fk {
	background-image: url(../flags/4x3/fk.svg)
}

.flag-icon-fk.flag-icon-squared {
	background-image: url(../flags/1x1/fk.svg)
}

.flag-icon-fm {
	background-image: url(../flags/4x3/fm.svg)
}

.flag-icon-fm.flag-icon-squared {
	background-image: url(../flags/1x1/fm.svg)
}

.flag-icon-fo {
	background-image: url(../flags/4x3/fo.svg)
}

.flag-icon-fo.flag-icon-squared {
	background-image: url(../flags/1x1/fo.svg)
}

.flag-icon-fr {
	background-image: url(../flags/4x3/fr.svg)
}

.flag-icon-fr.flag-icon-squared {
	background-image: url(../flags/1x1/fr.svg)
}

.flag-icon-ga {
	background-image: url(../flags/4x3/ga.svg)
}

.flag-icon-ga.flag-icon-squared {
	background-image: url(../flags/1x1/ga.svg)
}

.flag-icon-gb {
	background-image: url(../flags/4x3/gb.svg)
}

.flag-icon-gb.flag-icon-squared {
	background-image: url(../flags/1x1/gb.svg)
}

.flag-icon-gd {
	background-image: url(../flags/4x3/gd.svg)
}

.flag-icon-gd.flag-icon-squared {
	background-image: url(../flags/1x1/gd.svg)
}

.flag-icon-ge {
	background-image: url(../flags/4x3/ge.svg)
}

.flag-icon-ge.flag-icon-squared {
	background-image: url(../flags/1x1/ge.svg)
}

.flag-icon-gf {
	background-image: url(../flags/4x3/gf.svg)
}

.flag-icon-gf.flag-icon-squared {
	background-image: url(../flags/1x1/gf.svg)
}

.flag-icon-gg {
	background-image: url(../flags/4x3/gg.svg)
}

.flag-icon-gg.flag-icon-squared {
	background-image: url(../flags/1x1/gg.svg)
}

.flag-icon-gh {
	background-image: url(../flags/4x3/gh.svg)
}

.flag-icon-gh.flag-icon-squared {
	background-image: url(../flags/1x1/gh.svg)
}

.flag-icon-gi {
	background-image: url(../flags/4x3/gi.svg)
}

.flag-icon-gi.flag-icon-squared {
	background-image: url(../flags/1x1/gi.svg)
}

.flag-icon-gl {
	background-image: url(../flags/4x3/gl.svg)
}

.flag-icon-gl.flag-icon-squared {
	background-image: url(../flags/1x1/gl.svg)
}

.flag-icon-gm {
	background-image: url(../flags/4x3/gm.svg)
}

.flag-icon-gm.flag-icon-squared {
	background-image: url(../flags/1x1/gm.svg)
}

.flag-icon-gn {
	background-image: url(../flags/4x3/gn.svg)
}

.flag-icon-gn.flag-icon-squared {
	background-image: url(../flags/1x1/gn.svg)
}

.flag-icon-gp {
	background-image: url(../flags/4x3/gp.svg)
}

.flag-icon-gp.flag-icon-squared {
	background-image: url(../flags/1x1/gp.svg)
}

.flag-icon-gq {
	background-image: url(../flags/4x3/gq.svg)
}

.flag-icon-gq.flag-icon-squared {
	background-image: url(../flags/1x1/gq.svg)
}

.flag-icon-gr {
	background-image: url(../flags/4x3/gr.svg)
}

.flag-icon-gr.flag-icon-squared {
	background-image: url(../flags/1x1/gr.svg)
}

.flag-icon-gs {
	background-image: url(../flags/4x3/gs.svg)
}

.flag-icon-gs.flag-icon-squared {
	background-image: url(../flags/1x1/gs.svg)
}

.flag-icon-gt {
	background-image: url(../flags/4x3/gt.svg)
}

.flag-icon-gt.flag-icon-squared {
	background-image: url(../flags/1x1/gt.svg)
}

.flag-icon-gu {
	background-image: url(../flags/4x3/gu.svg)
}

.flag-icon-gu.flag-icon-squared {
	background-image: url(../flags/1x1/gu.svg)
}

.flag-icon-gw {
	background-image: url(../flags/4x3/gw.svg)
}

.flag-icon-gw.flag-icon-squared {
	background-image: url(../flags/1x1/gw.svg)
}

.flag-icon-gy {
	background-image: url(../flags/4x3/gy.svg)
}

.flag-icon-gy.flag-icon-squared {
	background-image: url(../flags/1x1/gy.svg)
}

.flag-icon-hk {
	background-image: url(../flags/4x3/hk.svg)
}

.flag-icon-hk.flag-icon-squared {
	background-image: url(../flags/1x1/hk.svg)
}

.flag-icon-hm {
	background-image: url(../flags/4x3/hm.svg)
}

.flag-icon-hm.flag-icon-squared {
	background-image: url(../flags/1x1/hm.svg)
}

.flag-icon-hn {
	background-image: url(../flags/4x3/hn.svg)
}

.flag-icon-hn.flag-icon-squared {
	background-image: url(../flags/1x1/hn.svg)
}

.flag-icon-hr {
	background-image: url(../flags/4x3/hr.svg)
}

.flag-icon-hr.flag-icon-squared {
	background-image: url(../flags/1x1/hr.svg)
}

.flag-icon-ht {
	background-image: url(../flags/4x3/ht.svg)
}

.flag-icon-ht.flag-icon-squared {
	background-image: url(../flags/1x1/ht.svg)
}

.flag-icon-hu {
	background-image: url(../flags/4x3/hu.svg)
}

.flag-icon-hu.flag-icon-squared {
	background-image: url(../flags/1x1/hu.svg)
}

.flag-icon-id {
	background-image: url(../flags/4x3/id.svg)
}

.flag-icon-id.flag-icon-squared {
	background-image: url(../flags/1x1/id.svg)
}

.flag-icon-ie {
	background-image: url(../flags/4x3/ie.svg)
}

.flag-icon-ie.flag-icon-squared {
	background-image: url(../flags/1x1/ie.svg)
}

.flag-icon-il {
	background-image: url(../flags/4x3/il.svg)
}

.flag-icon-il.flag-icon-squared {
	background-image: url(../flags/1x1/il.svg)
}

.flag-icon-im {
	background-image: url(../flags/4x3/im.svg)
}

.flag-icon-im.flag-icon-squared {
	background-image: url(../flags/1x1/im.svg)
}

.flag-icon-in {
	background-image: url(../flags/4x3/in.svg)
}

.flag-icon-in.flag-icon-squared {
	background-image: url(../flags/1x1/in.svg)
}

.flag-icon-io {
	background-image: url(../flags/4x3/io.svg)
}

.flag-icon-io.flag-icon-squared {
	background-image: url(../flags/1x1/io.svg)
}

.flag-icon-iq {
	background-image: url(../flags/4x3/iq.svg)
}

.flag-icon-iq.flag-icon-squared {
	background-image: url(../flags/1x1/iq.svg)
}

.flag-icon-ir {
	background-image: url(../flags/4x3/ir.svg)
}

.flag-icon-ir.flag-icon-squared {
	background-image: url(../flags/1x1/ir.svg)
}

.flag-icon-is {
	background-image: url(../flags/4x3/is.svg)
}

.flag-icon-is.flag-icon-squared {
	background-image: url(../flags/1x1/is.svg)
}

.flag-icon-it {
	background-image: url(../flags/4x3/it.svg)
}

.flag-icon-it.flag-icon-squared {
	background-image: url(../flags/1x1/it.svg)
}

.flag-icon-je {
	background-image: url(../flags/4x3/je.svg)
}

.flag-icon-je.flag-icon-squared {
	background-image: url(../flags/1x1/je.svg)
}

.flag-icon-jm {
	background-image: url(../flags/4x3/jm.svg)
}

.flag-icon-jm.flag-icon-squared {
	background-image: url(../flags/1x1/jm.svg)
}

.flag-icon-jo {
	background-image: url(../flags/4x3/jo.svg)
}

.flag-icon-jo.flag-icon-squared {
	background-image: url(../flags/1x1/jo.svg)
}

.flag-icon-jp {
	background-image: url(../flags/4x3/jp.svg)
}

.flag-icon-jp.flag-icon-squared {
	background-image: url(../flags/1x1/jp.svg)
}

.flag-icon-ke {
	background-image: url(../flags/4x3/ke.svg)
}

.flag-icon-ke.flag-icon-squared {
	background-image: url(../flags/1x1/ke.svg)
}

.flag-icon-kg {
	background-image: url(../flags/4x3/kg.svg)
}

.flag-icon-kg.flag-icon-squared {
	background-image: url(../flags/1x1/kg.svg)
}

.flag-icon-kh {
	background-image: url(../flags/4x3/kh.svg)
}

.flag-icon-kh.flag-icon-squared {
	background-image: url(../flags/1x1/kh.svg)
}

.flag-icon-ki {
	background-image: url(../flags/4x3/ki.svg)
}

.flag-icon-ki.flag-icon-squared {
	background-image: url(../flags/1x1/ki.svg)
}

.flag-icon-km {
	background-image: url(../flags/4x3/km.svg)
}

.flag-icon-km.flag-icon-squared {
	background-image: url(../flags/1x1/km.svg)
}

.flag-icon-kn {
	background-image: url(../flags/4x3/kn.svg)
}

.flag-icon-kn.flag-icon-squared {
	background-image: url(../flags/1x1/kn.svg)
}

.flag-icon-kp {
	background-image: url(../flags/4x3/kp.svg)
}

.flag-icon-kp.flag-icon-squared {
	background-image: url(../flags/1x1/kp.svg)
}

.flag-icon-kr {
	background-image: url(../flags/4x3/kr.svg)
}

.flag-icon-kr.flag-icon-squared {
	background-image: url(../flags/1x1/kr.svg)
}

.flag-icon-kw {
	background-image: url(../flags/4x3/kw.svg)
}

.flag-icon-kw.flag-icon-squared {
	background-image: url(../flags/1x1/kw.svg)
}

.flag-icon-ky {
	background-image: url(../flags/4x3/ky.svg)
}

.flag-icon-ky.flag-icon-squared {
	background-image: url(../flags/1x1/ky.svg)
}

.flag-icon-kz {
	background-image: url(../flags/4x3/kz.svg)
}

.flag-icon-kz.flag-icon-squared {
	background-image: url(../flags/1x1/kz.svg)
}

.flag-icon-la {
	background-image: url(../flags/4x3/la.svg)
}

.flag-icon-la.flag-icon-squared {
	background-image: url(../flags/1x1/la.svg)
}

.flag-icon-lb {
	background-image: url(../flags/4x3/lb.svg)
}

.flag-icon-lb.flag-icon-squared {
	background-image: url(../flags/1x1/lb.svg)
}

.flag-icon-lc {
	background-image: url(../flags/4x3/lc.svg)
}

.flag-icon-lc.flag-icon-squared {
	background-image: url(../flags/1x1/lc.svg)
}

.flag-icon-li {
	background-image: url(../flags/4x3/li.svg)
}

.flag-icon-li.flag-icon-squared {
	background-image: url(../flags/1x1/li.svg)
}

.flag-icon-lk {
	background-image: url(../flags/4x3/lk.svg)
}

.flag-icon-lk.flag-icon-squared {
	background-image: url(../flags/1x1/lk.svg)
}

.flag-icon-lr {
	background-image: url(../flags/4x3/lr.svg)
}

.flag-icon-lr.flag-icon-squared {
	background-image: url(../flags/1x1/lr.svg)
}

.flag-icon-ls {
	background-image: url(../flags/4x3/ls.svg)
}

.flag-icon-ls.flag-icon-squared {
	background-image: url(../flags/1x1/ls.svg)
}

.flag-icon-lt {
	background-image: url(../flags/4x3/lt.svg)
}

.flag-icon-lt.flag-icon-squared {
	background-image: url(../flags/1x1/lt.svg)
}

.flag-icon-lu {
	background-image: url(../flags/4x3/lu.svg)
}

.flag-icon-lu.flag-icon-squared {
	background-image: url(../flags/1x1/lu.svg)
}

.flag-icon-lv {
	background-image: url(../flags/4x3/lv.svg)
}

.flag-icon-lv.flag-icon-squared {
	background-image: url(../flags/1x1/lv.svg)
}

.flag-icon-ly {
	background-image: url(../flags/4x3/ly.svg)
}

.flag-icon-ly.flag-icon-squared {
	background-image: url(../flags/1x1/ly.svg)
}

.flag-icon-ma {
	background-image: url(../flags/4x3/ma.svg)
}

.flag-icon-ma.flag-icon-squared {
	background-image: url(../flags/1x1/ma.svg)
}

.flag-icon-mc {
	background-image: url(../flags/4x3/mc.svg)
}

.flag-icon-mc.flag-icon-squared {
	background-image: url(../flags/1x1/mc.svg)
}

.flag-icon-md {
	background-image: url(../flags/4x3/md.svg)
}

.flag-icon-md.flag-icon-squared {
	background-image: url(../flags/1x1/md.svg)
}

.flag-icon-me {
	background-image: url(../flags/4x3/me.svg)
}

.flag-icon-me.flag-icon-squared {
	background-image: url(../flags/1x1/me.svg)
}

.flag-icon-mf {
	background-image: url(../flags/4x3/mf.svg)
}

.flag-icon-mf.flag-icon-squared {
	background-image: url(../flags/1x1/mf.svg)
}

.flag-icon-mg {
	background-image: url(../flags/4x3/mg.svg)
}

.flag-icon-mg.flag-icon-squared {
	background-image: url(../flags/1x1/mg.svg)
}

.flag-icon-mh {
	background-image: url(../flags/4x3/mh.svg)
}

.flag-icon-mh.flag-icon-squared {
	background-image: url(../flags/1x1/mh.svg)
}

.flag-icon-mk {
	background-image: url(../flags/4x3/mk.svg)
}

.flag-icon-mk.flag-icon-squared {
	background-image: url(../flags/1x1/mk.svg)
}

.flag-icon-ml {
	background-image: url(../flags/4x3/ml.svg)
}

.flag-icon-ml.flag-icon-squared {
	background-image: url(../flags/1x1/ml.svg)
}

.flag-icon-mm {
	background-image: url(../flags/4x3/mm.svg)
}

.flag-icon-mm.flag-icon-squared {
	background-image: url(../flags/1x1/mm.svg)
}

.flag-icon-mn {
	background-image: url(../flags/4x3/mn.svg)
}

.flag-icon-mn.flag-icon-squared {
	background-image: url(../flags/1x1/mn.svg)
}

.flag-icon-mo {
	background-image: url(../flags/4x3/mo.svg)
}

.flag-icon-mo.flag-icon-squared {
	background-image: url(../flags/1x1/mo.svg)
}

.flag-icon-mp {
	background-image: url(../flags/4x3/mp.svg)
}

.flag-icon-mp.flag-icon-squared {
	background-image: url(../flags/1x1/mp.svg)
}

.flag-icon-mq {
	background-image: url(../flags/4x3/mq.svg)
}

.flag-icon-mq.flag-icon-squared {
	background-image: url(../flags/1x1/mq.svg)
}

.flag-icon-mr {
	background-image: url(../flags/4x3/mr.svg)
}

.flag-icon-mr.flag-icon-squared {
	background-image: url(../flags/1x1/mr.svg)
}

.flag-icon-ms {
	background-image: url(../flags/4x3/ms.svg)
}

.flag-icon-ms.flag-icon-squared {
	background-image: url(../flags/1x1/ms.svg)
}

.flag-icon-mt {
	background-image: url(../flags/4x3/mt.svg)
}

.flag-icon-mt.flag-icon-squared {
	background-image: url(../flags/1x1/mt.svg)
}

.flag-icon-mu {
	background-image: url(../flags/4x3/mu.svg)
}

.flag-icon-mu.flag-icon-squared {
	background-image: url(../flags/1x1/mu.svg)
}

.flag-icon-mv {
	background-image: url(../flags/4x3/mv.svg)
}

.flag-icon-mv.flag-icon-squared {
	background-image: url(../flags/1x1/mv.svg)
}

.flag-icon-mw {
	background-image: url(../flags/4x3/mw.svg)
}

.flag-icon-mw.flag-icon-squared {
	background-image: url(../flags/1x1/mw.svg)
}

.flag-icon-mx {
	background-image: url(../flags/4x3/mx.svg)
}

.flag-icon-mx.flag-icon-squared {
	background-image: url(../flags/1x1/mx.svg)
}

.flag-icon-my {
	background-image: url(../flags/4x3/my.svg)
}

.flag-icon-my.flag-icon-squared {
	background-image: url(../flags/1x1/my.svg)
}

.flag-icon-mz {
	background-image: url(../flags/4x3/mz.svg)
}

.flag-icon-mz.flag-icon-squared {
	background-image: url(../flags/1x1/mz.svg)
}

.flag-icon-na {
	background-image: url(../flags/4x3/na.svg)
}

.flag-icon-na.flag-icon-squared {
	background-image: url(../flags/1x1/na.svg)
}

.flag-icon-nc {
	background-image: url(../flags/4x3/nc.svg)
}

.flag-icon-nc.flag-icon-squared {
	background-image: url(../flags/1x1/nc.svg)
}

.flag-icon-ne {
	background-image: url(../flags/4x3/ne.svg)
}

.flag-icon-ne.flag-icon-squared {
	background-image: url(../flags/1x1/ne.svg)
}

.flag-icon-nf {
	background-image: url(../flags/4x3/nf.svg)
}

.flag-icon-nf.flag-icon-squared {
	background-image: url(../flags/1x1/nf.svg)
}

.flag-icon-ng {
	background-image: url(../flags/4x3/ng.svg)
}

.flag-icon-ng.flag-icon-squared {
	background-image: url(../flags/1x1/ng.svg)
}

.flag-icon-ni {
	background-image: url(../flags/4x3/ni.svg)
}

.flag-icon-ni.flag-icon-squared {
	background-image: url(../flags/1x1/ni.svg)
}

.flag-icon-nl {
	background-image: url(../flags/4x3/nl.svg)
}

.flag-icon-nl.flag-icon-squared {
	background-image: url(../flags/1x1/nl.svg)
}

.flag-icon-no {
	background-image: url(../flags/4x3/no.svg)
}

.flag-icon-no.flag-icon-squared {
	background-image: url(../flags/1x1/no.svg)
}

.flag-icon-np {
	background-image: url(../flags/4x3/np.svg)
}

.flag-icon-np.flag-icon-squared {
	background-image: url(../flags/1x1/np.svg)
}

.flag-icon-nr {
	background-image: url(../flags/4x3/nr.svg)
}

.flag-icon-nr.flag-icon-squared {
	background-image: url(../flags/1x1/nr.svg)
}

.flag-icon-nu {
	background-image: url(../flags/4x3/nu.svg)
}

.flag-icon-nu.flag-icon-squared {
	background-image: url(../flags/1x1/nu.svg)
}

.flag-icon-nz {
	background-image: url(../flags/4x3/nz.svg)
}

.flag-icon-nz.flag-icon-squared {
	background-image: url(../flags/1x1/nz.svg)
}

.flag-icon-om {
	background-image: url(../flags/4x3/om.svg)
}

.flag-icon-om.flag-icon-squared {
	background-image: url(../flags/1x1/om.svg)
}

.flag-icon-pa {
	background-image: url(../flags/4x3/pa.svg)
}

.flag-icon-pa.flag-icon-squared {
	background-image: url(../flags/1x1/pa.svg)
}

.flag-icon-pe {
	background-image: url(../flags/4x3/pe.svg)
}

.flag-icon-pe.flag-icon-squared {
	background-image: url(../flags/1x1/pe.svg)
}

.flag-icon-pf {
	background-image: url(../flags/4x3/pf.svg)
}

.flag-icon-pf.flag-icon-squared {
	background-image: url(../flags/1x1/pf.svg)
}

.flag-icon-pg {
	background-image: url(../flags/4x3/pg.svg)
}

.flag-icon-pg.flag-icon-squared {
	background-image: url(../flags/1x1/pg.svg)
}

.flag-icon-ph {
	background-image: url(../flags/4x3/ph.svg)
}

.flag-icon-ph.flag-icon-squared {
	background-image: url(../flags/1x1/ph.svg)
}

.flag-icon-pk {
	background-image: url(../flags/4x3/pk.svg)
}

.flag-icon-pk.flag-icon-squared {
	background-image: url(../flags/1x1/pk.svg)
}

.flag-icon-pl {
	background-image: url(../flags/4x3/pl.svg)
}

.flag-icon-pl.flag-icon-squared {
	background-image: url(../flags/1x1/pl.svg)
}

.flag-icon-pm {
	background-image: url(../flags/4x3/pm.svg)
}

.flag-icon-pm.flag-icon-squared {
	background-image: url(../flags/1x1/pm.svg)
}

.flag-icon-pn {
	background-image: url(../flags/4x3/pn.svg)
}

.flag-icon-pn.flag-icon-squared {
	background-image: url(../flags/1x1/pn.svg)
}

.flag-icon-pr {
	background-image: url(../flags/4x3/pr.svg)
}

.flag-icon-pr.flag-icon-squared {
	background-image: url(../flags/1x1/pr.svg)
}

.flag-icon-ps {
	background-image: url(../flags/4x3/ps.svg)
}

.flag-icon-ps.flag-icon-squared {
	background-image: url(../flags/1x1/ps.svg)
}

.flag-icon-pt {
	background-image: url(../flags/4x3/pt.svg)
}

.flag-icon-pt.flag-icon-squared {
	background-image: url(../flags/1x1/pt.svg)
}

.flag-icon-pw {
	background-image: url(../flags/4x3/pw.svg)
}

.flag-icon-pw.flag-icon-squared {
	background-image: url(../flags/1x1/pw.svg)
}

.flag-icon-py {
	background-image: url(../flags/4x3/py.svg)
}

.flag-icon-py.flag-icon-squared {
	background-image: url(../flags/1x1/py.svg)
}

.flag-icon-qa {
	background-image: url(../flags/4x3/qa.svg)
}

.flag-icon-qa.flag-icon-squared {
	background-image: url(../flags/1x1/qa.svg)
}

.flag-icon-re {
	background-image: url(../flags/4x3/re.svg)
}

.flag-icon-re.flag-icon-squared {
	background-image: url(../flags/1x1/re.svg)
}

.flag-icon-ro {
	background-image: url(../flags/4x3/ro.svg)
}

.flag-icon-ro.flag-icon-squared {
	background-image: url(../flags/1x1/ro.svg)
}

.flag-icon-rs {
	background-image: url(../flags/4x3/rs.svg)
}

.flag-icon-rs.flag-icon-squared {
	background-image: url(../flags/1x1/rs.svg)
}

.flag-icon-ru {
	background-image: url(../flags/4x3/ru.svg)
}

.flag-icon-ru.flag-icon-squared {
	background-image: url(../flags/1x1/ru.svg)
}

.flag-icon-rw {
	background-image: url(../flags/4x3/rw.svg)
}

.flag-icon-rw.flag-icon-squared {
	background-image: url(../flags/1x1/rw.svg)
}

.flag-icon-sa {
	background-image: url(../flags/4x3/sa.svg)
}

.flag-icon-sa.flag-icon-squared {
	background-image: url(../flags/1x1/sa.svg)
}

.flag-icon-sb {
	background-image: url(../flags/4x3/sb.svg)
}

.flag-icon-sb.flag-icon-squared {
	background-image: url(../flags/1x1/sb.svg)
}

.flag-icon-sc {
	background-image: url(../flags/4x3/sc.svg)
}

.flag-icon-sc.flag-icon-squared {
	background-image: url(../flags/1x1/sc.svg)
}

.flag-icon-sd {
	background-image: url(../flags/4x3/sd.svg)
}

.flag-icon-sd.flag-icon-squared {
	background-image: url(../flags/1x1/sd.svg)
}

.flag-icon-se {
	background-image: url(../flags/4x3/se.svg)
}

.flag-icon-se.flag-icon-squared {
	background-image: url(../flags/1x1/se.svg)
}

.flag-icon-sg {
	background-image: url(../flags/4x3/sg.svg)
}

.flag-icon-sg.flag-icon-squared {
	background-image: url(../flags/1x1/sg.svg)
}

.flag-icon-sh {
	background-image: url(../flags/4x3/sh.svg)
}

.flag-icon-sh.flag-icon-squared {
	background-image: url(../flags/1x1/sh.svg)
}

.flag-icon-si {
	background-image: url(../flags/4x3/si.svg)
}

.flag-icon-si.flag-icon-squared {
	background-image: url(../flags/1x1/si.svg)
}

.flag-icon-sj {
	background-image: url(../flags/4x3/sj.svg)
}

.flag-icon-sj.flag-icon-squared {
	background-image: url(../flags/1x1/sj.svg)
}

.flag-icon-sk {
	background-image: url(../flags/4x3/sk.svg)
}

.flag-icon-sk.flag-icon-squared {
	background-image: url(../flags/1x1/sk.svg)
}

.flag-icon-sl {
	background-image: url(../flags/4x3/sl.svg)
}

.flag-icon-sl.flag-icon-squared {
	background-image: url(../flags/1x1/sl.svg)
}

.flag-icon-sm {
	background-image: url(../flags/4x3/sm.svg)
}

.flag-icon-sm.flag-icon-squared {
	background-image: url(../flags/1x1/sm.svg)
}

.flag-icon-sn {
	background-image: url(../flags/4x3/sn.svg)
}

.flag-icon-sn.flag-icon-squared {
	background-image: url(../flags/1x1/sn.svg)
}

.flag-icon-so {
	background-image: url(../flags/4x3/so.svg)
}

.flag-icon-so.flag-icon-squared {
	background-image: url(../flags/1x1/so.svg)
}

.flag-icon-sr {
	background-image: url(../flags/4x3/sr.svg)
}

.flag-icon-sr.flag-icon-squared {
	background-image: url(../flags/1x1/sr.svg)
}

.flag-icon-ss {
	background-image: url(../flags/4x3/ss.svg)
}

.flag-icon-ss.flag-icon-squared {
	background-image: url(../flags/1x1/ss.svg)
}

.flag-icon-st {
	background-image: url(../flags/4x3/st.svg)
}

.flag-icon-st.flag-icon-squared {
	background-image: url(../flags/1x1/st.svg)
}

.flag-icon-sv {
	background-image: url(../flags/4x3/sv.svg)
}

.flag-icon-sv.flag-icon-squared {
	background-image: url(../flags/1x1/sv.svg)
}

.flag-icon-sx {
	background-image: url(../flags/4x3/sx.svg)
}

.flag-icon-sx.flag-icon-squared {
	background-image: url(../flags/1x1/sx.svg)
}

.flag-icon-sy {
	background-image: url(../flags/4x3/sy.svg)
}

.flag-icon-sy.flag-icon-squared {
	background-image: url(../flags/1x1/sy.svg)
}

.flag-icon-sz {
	background-image: url(../flags/4x3/sz.svg)
}

.flag-icon-sz.flag-icon-squared {
	background-image: url(../flags/1x1/sz.svg)
}

.flag-icon-tc {
	background-image: url(../flags/4x3/tc.svg)
}

.flag-icon-tc.flag-icon-squared {
	background-image: url(../flags/1x1/tc.svg)
}

.flag-icon-td {
	background-image: url(../flags/4x3/td.svg)
}

.flag-icon-td.flag-icon-squared {
	background-image: url(../flags/1x1/td.svg)
}

.flag-icon-tf {
	background-image: url(../flags/4x3/tf.svg)
}

.flag-icon-tf.flag-icon-squared {
	background-image: url(../flags/1x1/tf.svg)
}

.flag-icon-tg {
	background-image: url(../flags/4x3/tg.svg)
}

.flag-icon-tg.flag-icon-squared {
	background-image: url(../flags/1x1/tg.svg)
}

.flag-icon-th {
	background-image: url(../flags/4x3/th.svg)
}

.flag-icon-th.flag-icon-squared {
	background-image: url(../flags/1x1/th.svg)
}

.flag-icon-tj {
	background-image: url(../flags/4x3/tj.svg)
}

.flag-icon-tj.flag-icon-squared {
	background-image: url(../flags/1x1/tj.svg)
}

.flag-icon-tk {
	background-image: url(../flags/4x3/tk.svg)
}

.flag-icon-tk.flag-icon-squared {
	background-image: url(../flags/1x1/tk.svg)
}

.flag-icon-tl {
	background-image: url(../flags/4x3/tl.svg)
}

.flag-icon-tl.flag-icon-squared {
	background-image: url(../flags/1x1/tl.svg)
}

.flag-icon-tm {
	background-image: url(../flags/4x3/tm.svg)
}

.flag-icon-tm.flag-icon-squared {
	background-image: url(../flags/1x1/tm.svg)
}

.flag-icon-tn {
	background-image: url(../flags/4x3/tn.svg)
}

.flag-icon-tn.flag-icon-squared {
	background-image: url(../flags/1x1/tn.svg)
}

.flag-icon-to {
	background-image: url(../flags/4x3/to.svg)
}

.flag-icon-to.flag-icon-squared {
	background-image: url(../flags/1x1/to.svg)
}

.flag-icon-tr {
	background-image: url(../flags/4x3/tr.svg)
}

.flag-icon-tr.flag-icon-squared {
	background-image: url(../flags/1x1/tr.svg)
}

.flag-icon-tt {
	background-image: url(../flags/4x3/tt.svg)
}

.flag-icon-tt.flag-icon-squared {
	background-image: url(../flags/1x1/tt.svg)
}

.flag-icon-tv {
	background-image: url(../flags/4x3/tv.svg)
}

.flag-icon-tv.flag-icon-squared {
	background-image: url(../flags/1x1/tv.svg)
}

.flag-icon-tw {
	background-image: url(../flags/4x3/tw.svg)
}

.flag-icon-tw.flag-icon-squared {
	background-image: url(../flags/1x1/tw.svg)
}

.flag-icon-tz {
	background-image: url(../flags/4x3/tz.svg)
}

.flag-icon-tz.flag-icon-squared {
	background-image: url(../flags/1x1/tz.svg)
}

.flag-icon-ua {
	background-image: url(../flags/4x3/ua.svg)
}

.flag-icon-ua.flag-icon-squared {
	background-image: url(../flags/1x1/ua.svg)
}

.flag-icon-ug {
	background-image: url(../flags/4x3/ug.svg)
}

.flag-icon-ug.flag-icon-squared {
	background-image: url(../flags/1x1/ug.svg)
}

.flag-icon-um {
	background-image: url(../flags/4x3/um.svg)
}

.flag-icon-um.flag-icon-squared {
	background-image: url(../flags/1x1/um.svg)
}

.flag-icon-us {
	background-image: url(../flags/4x3/us.svg)
}

.flag-icon-us.flag-icon-squared {
	background-image: url(../flags/1x1/us.svg)
}

.flag-icon-uy {
	background-image: url(../flags/4x3/uy.svg)
}

.flag-icon-uy.flag-icon-squared {
	background-image: url(../flags/1x1/uy.svg)
}

.flag-icon-uz {
	background-image: url(../flags/4x3/uz.svg)
}

.flag-icon-uz.flag-icon-squared {
	background-image: url(../flags/1x1/uz.svg)
}

.flag-icon-va {
	background-image: url(../flags/4x3/va.svg)
}

.flag-icon-va.flag-icon-squared {
	background-image: url(../flags/1x1/va.svg)
}

.flag-icon-vc {
	background-image: url(../flags/4x3/vc.svg)
}

.flag-icon-vc.flag-icon-squared {
	background-image: url(../flags/1x1/vc.svg)
}

.flag-icon-ve {
	background-image: url(../flags/4x3/ve.svg)
}

.flag-icon-ve.flag-icon-squared {
	background-image: url(../flags/1x1/ve.svg)
}

.flag-icon-vg {
	background-image: url(../flags/4x3/vg.svg)
}

.flag-icon-vg.flag-icon-squared {
	background-image: url(../flags/1x1/vg.svg)
}

.flag-icon-vi {
	background-image: url(../flags/4x3/vi.svg)
}

.flag-icon-vi.flag-icon-squared {
	background-image: url(../flags/1x1/vi.svg)
}

.flag-icon-vn {
	background-image: url(../flags/4x3/vn.svg)
}

.flag-icon-vn.flag-icon-squared {
	background-image: url(../flags/1x1/vn.svg)
}

.flag-icon-vu {
	background-image: url(../flags/4x3/vu.svg)
}

.flag-icon-vu.flag-icon-squared {
	background-image: url(../flags/1x1/vu.svg)
}

.flag-icon-wf {
	background-image: url(../flags/4x3/wf.svg)
}

.flag-icon-wf.flag-icon-squared {
	background-image: url(../flags/1x1/wf.svg)
}

.flag-icon-ws {
	background-image: url(../flags/4x3/ws.svg)
}

.flag-icon-ws.flag-icon-squared {
	background-image: url(../flags/1x1/ws.svg)
}

.flag-icon-ye {
	background-image: url(../flags/4x3/ye.svg)
}

.flag-icon-ye.flag-icon-squared {
	background-image: url(../flags/1x1/ye.svg)
}

.flag-icon-yt {
	background-image: url(../flags/4x3/yt.svg)
}

.flag-icon-yt.flag-icon-squared {
	background-image: url(../flags/1x1/yt.svg)
}

.flag-icon-za {
	background-image: url(../flags/4x3/za.svg)
}

.flag-icon-za.flag-icon-squared {
	background-image: url(../flags/1x1/za.svg)
}

.flag-icon-zm {
	background-image: url(../flags/4x3/zm.svg)
}

.flag-icon-zm.flag-icon-squared {
	background-image: url(../flags/1x1/zm.svg)
}

.flag-icon-zw {
	background-image: url(../flags/4x3/zw.svg)
}

.flag-icon-zw.flag-icon-squared {
	background-image: url(../flags/1x1/zw.svg)
}

.flag-icon-es-ct {
	background-image: url(../flags/4x3/es-ct.svg)
}

.flag-icon-es-ct.flag-icon-squared {
	background-image: url(../flags/1x1/es-ct.svg)
}

.flag-icon-eu {
	background-image: url(../flags/4x3/eu.svg)
}

.flag-icon-eu.flag-icon-squared {
	background-image: url(../flags/1x1/eu.svg)
}

.flag-icon-gb-eng {
	background-image: url(../flags/4x3/gb-eng.svg)
}

.flag-icon-gb-eng.flag-icon-squared {
	background-image: url(../flags/1x1/gb-eng.svg)
}

.flag-icon-gb-nir {
	background-image: url(../flags/4x3/gb-nir.svg)
}

.flag-icon-gb-nir.flag-icon-squared {
	background-image: url(../flags/1x1/gb-nir.svg)
}

.flag-icon-gb-sct {
	background-image: url(../flags/4x3/gb-sct.svg)
}

.flag-icon-gb-sct.flag-icon-squared {
	background-image: url(../flags/1x1/gb-sct.svg)
}

.flag-icon-gb-wls {
	background-image: url(../flags/4x3/gb-wls.svg)
}

.flag-icon-gb-wls.flag-icon-squared {
	background-image: url(../flags/1x1/gb-wls.svg)
}

.flag-icon-un {
	background-image: url(../flags/4x3/un.svg)
}

.flag-icon-un.flag-icon-squared {
	background-image: url(../flags/1x1/un.svg)
}